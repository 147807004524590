import {
  GetSpentParams,
  SpentCategoryByMonthParamsType,
  SpentCategoryTopEarnedParamsType,
  SpentTransactionByMerchantParamsType
} from 'types';
import axiosClient from './axiosClient';
import { endpoints } from './endpoints';

export const getSpendingByCategory = async (params?: GetSpentParams) => {
  const resp = await axiosClient.get(endpoints.spendingByCategory, { params });
  return resp.data;
};

export const getSpendingByGroup = async (group: 'subscription' | 'bill', params: GetSpentParams) => {
  const resp = await axiosClient.get(`${endpoints.spendingByGroup}/${group}`, { params });
  return resp.data;
};

export const getSpentCategoryByMonth = async (params: SpentCategoryByMonthParamsType) => {
  const resp = await axiosClient.get(`${endpoints.spentCategoryByMonth}`, { params });
  return resp.data;
};

export const getSpentCategoryTopEarned = async (params: SpentCategoryTopEarnedParamsType) => {
  const resp = await axiosClient.get(`${endpoints.spentCategoryTopEarned}`, { params });
  return resp.data;
};

export const getSpentByMerchant = async (merchant: string) => {
  const resp = await axiosClient.get(`${endpoints.spentByMerchant}/${merchant}`);
  return resp.data;
};

export const getSpentTransactionsByMerchant = async (
  params: SpentTransactionByMerchantParamsType,
  merchant: string
) => {
  const resp = await axiosClient.get(`${endpoints.spentByMerchant}/${merchant}/transactions`, { params });
  return resp.data;
};
