import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import './style.scss';
import { SpentCategoryRecordType, SpentCategoryTransactionRecordType, SpentRecordType } from 'types';
import { useAppStore } from 'store';
import { SpentByCategoryChart, SpentByCategoryTransaction } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import chevLeft from 'assets/images/chevron-left.svg';
import { fixMoney } from 'utils';
import { getSpendingByCategory, getSpentCategoryByMonth, getSpentCategoryTopEarned } from 'services';

type activeFilterReward = 'transaction_amount' | 'transaction_date';

const limit = 10;

export const SpentByCategoryDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const categoryParam = params?.category || '';

  const { setAppLoading } = useAppStore();
  const [activeFilterReward, setActiveFilterReward] = useState<activeFilterReward>('transaction_date');
  const [selectedTransaction, setSelectedTransaction] = useState<SpentCategoryRecordType | null>(null);
  const [spentByCategoryData, setSpentByCategoryData] = useState<SpentCategoryRecordType[]>([]);
  const [spentByAmount, setSpentByAmount] = useState<SpentCategoryTransactionRecordType[]>([]);
  const [spentByDate, setSpentByDate] = useState<SpentCategoryTransactionRecordType[]>([]);
  const [categoryData, setCategoryData] = useState<SpentRecordType | null>(null);
  const [hasMoreByAmount, setHasMoreByAmount] = useState(false);
  const [hasMoreByDate, setHasMoreByDate] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchTransactionsData();
  }, [selectedTransaction]);

  const fetchData = async () => {
    try {
      setAppLoading(true);
      const params = { category: categoryParam };
      const resp = await getSpentCategoryByMonth(params);
      setSpentByCategoryData(resp.data || []);
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const fetchTransactionsData = async () => {
    try {
      setAppLoading(true);
      const params = {
        category: categoryParam,
        order: '',
        limit,
        offset: 0,
        year: selectedTransaction?.year || undefined,
        month: selectedTransaction?.month || undefined
      };
      const resps = await Promise.all([
        getSpentCategoryTopEarned({ ...params, order: 'transaction_amount' }),
        getSpentCategoryTopEarned({ ...params, order: 'transaction_date' }),
        getSpendingByCategory({ ...params })
      ]);
      setSpentByAmount(resps[0].data || []);
      setSpentByDate(resps[1].data || []);
      setCategoryData(resps[2].data[0] || null);
      if (resps[0].data?.length < limit) {
        setHasMoreByAmount(false);
      } else {
        setHasMoreByAmount(true);
      }
      if (resps[1].data?.length < limit) {
        setHasMoreByDate(false);
      } else {
        setHasMoreByDate(true);
      }
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const fetchMoreTransactionsByAmount = async () => {
    try {
      setAppLoading(true);
      const params = {
        category: categoryParam,
        order: '',
        limit,
        offset: spentByAmount.length,
        year: selectedTransaction?.year || undefined,
        month: selectedTransaction?.month || undefined
      };
      const resp = await getSpentCategoryTopEarned({ ...params, order: 'transaction_amount' });
      setSpentByAmount([...spentByAmount, ...(resp.data || [])]);

      if (resp.data?.length < limit) {
        setHasMoreByAmount(false);
      } else {
        setHasMoreByAmount(true);
      }
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const fetchMoreTransactionsByDate = async () => {
    try {
      setAppLoading(true);
      const params = {
        category: categoryParam,
        order: '',
        limit,
        offset: spentByDate.length,
        year: selectedTransaction?.year || undefined,
        month: selectedTransaction?.month || undefined
      };
      const resp = await getSpentCategoryTopEarned({ ...params, order: 'transaction_date' });
      setSpentByDate([...spentByDate, ...(resp.data || [])]);

      if (resp.data?.length < limit) {
        setHasMoreByDate(false);
      } else {
        setHasMoreByDate(true);
      }
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleButtonRewardClick = (buttonType: activeFilterReward) => {
    setActiveFilterReward(buttonType);
  };

  const handleBackBtn = () => {
    navigate(-1);
  };

  const transactionData = useMemo(() => {
    switch (activeFilterReward) {
      case 'transaction_amount':
        return spentByAmount || [];
      case 'transaction_date':
        return spentByDate || [];
      default:
        return [];
    }
  }, [activeFilterReward, spentByAmount, spentByDate]);

  return (
    <>
      <div className="spent-by-category-transaction-container">
        <button className="spent-by-category-transaction-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <div className="spent-by-category-transaction-header">
          <h2 className="spent-by-category-transaction-header-title text-center">Spent by Category</h2>
        </div>
        <div className="spent-by-category-transaction--category mb-5 mt-4">
          <div>
            <img src={categoryData?.categoryImageUrl} alt="category" />
            <div className="spent-by-category-transaction--name">{categoryData?.category}</div>
          </div>
          <div>
            <div>${fixMoney(categoryData?.totalSpending || 0)}</div>
            <div>
              {selectedTransaction
                ? `${moment(`${selectedTransaction.month}/${selectedTransaction.year}`, 'M/YYYY').format("MMM'YY")}`
                : 'Last 12 months'}
            </div>
          </div>
        </div>
        {spentByCategoryData?.length > 0 && (
          <SpentByCategoryChart
            data={spentByCategoryData}
            selectedTransaction={selectedTransaction}
            setSelectedTransaction={setSelectedTransaction}
          />
        )}
        <div className="spent-by-category-transaction-box mt-4">
          <div className="spent-by-category-transaction-content">
            <div className="infobox">
              <h2 className="spent-by-category-transaction-title mb-3">Your Top Transactions</h2>
              <div className="infobox-filter">
                <button
                  className={`infobox-filter-left ${activeFilterReward === 'transaction_amount' ? 'active' : ''}`}
                  onClick={() => handleButtonRewardClick('transaction_amount')}>
                  By Amount
                </button>
                <button
                  className={`infobox-filter-right ${activeFilterReward === 'transaction_date' ? 'active' : ''}`}
                  onClick={() => handleButtonRewardClick('transaction_date')}>
                  By Date
                </button>
              </div>
              {transactionData?.length !== 0 && (
                <div className="infobox-label">
                  <span>Transaction</span>
                  <span>Spent</span>
                </div>
              )}
              {transactionData.map((item, key) => {
                return (
                  <SpentByCategoryTransaction
                    data={item}
                    key={key}
                    handleClick={() =>
                      navigate(
                        `/rewards-earned/report-issue/${item.mappedCardId}/${item?.id}/?isReported=${item.isSubmittedWrongCategorizationReport || false}`
                      )
                    }
                  />
                );
              })}
            </div>
          </div>
          {transactionData?.length > 0 &&
            ((activeFilterReward === 'transaction_amount' && hasMoreByAmount) ||
              (activeFilterReward === 'transaction_date' && hasMoreByDate)) && (
              <div
                className="btn-load-more"
                onClick={
                  activeFilterReward === 'transaction_amount'
                    ? fetchMoreTransactionsByAmount
                    : fetchMoreTransactionsByDate
                }>
                Load more
              </div>
            )}
        </div>
      </div>
    </>
  );
};
