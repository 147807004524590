import nextIcon from 'assets/images/next.png';
import './style.scss';
import { SpentCategoryTransactionRecordType } from 'types';
import moment from 'moment';
import { fixMoney } from 'utils';

type props = {
  data: SpentCategoryTransactionRecordType;
  handleClick?: () => void;
};

export const SpentByCategoryTransaction = ({ data, handleClick }: props) => {
  return (
    <div className="spent-category-transaction-item">
      <div className="spent-category-transaction-card">
        <img src={data?.cardImageUrl} alt="card" />
        <span className="name">{`${data?.institutionShortName}${data?.accountMask ? ` - ${data?.accountMask}` : ''}`}</span>
      </div>
      <div className="spent-category-transaction-infobox">
        <div className="info">
          <span className="info-name">{data?.merchantName}</span>
          <span className="info-date">Date: {moment(data?.transactionDate)?.format('MM/DD/YYYY')}</span>
        </div>
        <div className="reward">
          <span>${fixMoney(data?.transactionAmount)}</span>
          <img src={nextIcon} alt="next" className="d-none" />
        </div>
      </div>
      <span className="spent-category-transaction-report" onClick={handleClick ? handleClick : undefined}>
        {data?.isSubmittedWrongCategorizationReport ? 'Issue Reported!' : 'Report Issue'}
      </span>
    </div>
  );
};
