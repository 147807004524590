import ReactSlider from 'react-slider';
import './style.scss';
import { useMemo, useState } from 'react';
import { formatMoney } from 'utils';
import { ModalCustom } from 'components/ModalCustom';

type props = {
  handleClickLinkAccount: () => void;
  min?: number;
  max?: number;
  value: number;
  handleChangeSlide: (v: number) => void;
};

export const SpendCalculator = ({
  handleClickLinkAccount,
  min = 500,
  max = 10000,
  value,
  handleChangeSlide
}: props) => {
  const [openModal, setOpenModal] = useState(false);

  const getMissedValue = useMemo(() => {
    if (value >= 500 && value < 5000) {
      return (value * 12 * 2) / 100;
    }
    if (value >= 5000 && value < 15000) {
      return (value * 12 * 1.75) / 100;
    }
    return (value * 12 * 1.5) / 100;
  }, [value]);

  return (
    <div className="spend-calculator-container">
      <div className="spend-calculator-slider-container">
        <ReactSlider
          className="spend-calculator-slider"
          thumbClassName="spend-calculator-slider--thumb"
          trackClassName="spend-calculator-slider--track"
          thumbActiveClassName="spend-calculator-slider--thumb-active"
          min={min}
          max={max}
          value={value}
          renderThumb={(props, state) => <div {...props}></div>}
          step={100}
          onChange={(v) => handleChangeSlide(v)}
        />
        <div className="spend-calculator-slider-values">
          <div className="spend-calculator-slider--value">{`$${formatMoney(min)}`}</div>
          <div className="spend-calculator-slider--value">{`$${formatMoney(max)}`}</div>
        </div>
        <div className="spend-calculator-reward-container">
          <div className="spend-calculator-reward">
            <div className="spend-calculator-reward-spend--title">Monthly spend</div>
            <div className="spend-calculator-reward-spend--value">${formatMoney(value)}</div>
          </div>
          <div className="spend-calculator-reward">
            <div className="spend-calculator-reward-missed--title">
              Annual missed rewards{' '}
              <div className="spend-calculator-reward-missed--note" onClick={() => setOpenModal(true)}>
                i
              </div>
            </div>
            <div className="spend-calculator-reward-missed--value">${formatMoney(getMissedValue)}</div>
          </div>
        </div>
        <div className="spend-calculator--btn" onClick={handleClickLinkAccount}>
          Link your cards to maximize rewards
        </div>
      </div>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        onClose={() => setOpenModal(!openModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Annual Missed Rewards</div>
          <div>
            Annual Missed Rewards represent points, miles or cashback you could generally earn on the purchase but may
            miss out by not using your right cards every time.
            <br />
            <br />
            Missed rewards are estimated based on transaction amount, merchant, rewards offered by card used for
            purchases and adding them up for full year view.
            <br />
            <br />
            For example, if you spend $100 on dining or groceries using your card that gives 1% rewards, while you have
            another card that gives 4% rewards, Your missed rewards = $100*(4%-1%) = $3
            <br />
            <br />
            Of course, spending habits, your cards, type of merchants and other factors may impact estimated Missed
            Rewards, but they can be significant money left on the table.
          </div>
        </div>
      </ModalCustom>
    </div>
  );
};
