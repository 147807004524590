import { ModalCustom } from 'components/ModalCustom';
import './style.scss';

type props = {
  bankName: string;
  onClose: () => void;
  isOpen: boolean;
  handleConfirm: () => void;
};

export const ModalManualCardPending = ({ bankName, onClose, isOpen, handleConfirm }: props) => {
  return (
    <ModalCustom
      onClose={onClose}
      toggle={onClose}
      isOpen={isOpen}
      className="modal-blur modal-sm modal-manual-card-pending">
      <div className="modal-manual-card-pending">
        <div className="modal-manual-card-pending--title">{`${bankName} is pending for validation`}</div>
        <div className="modal-manual-card-pending--text">
          This card is being validated by Uthrive. There is no action needed from you.
        </div>
        <div className="modal-manual-card-pending--btns mt-5">
          <button className="modal-manual-card-pending--btn" onClick={handleConfirm}>
            Report an issue
          </button>
        </div>
      </div>
    </ModalCustom>
  );
};
