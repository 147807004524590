import React, { useEffect, useState } from 'react';
import './style.scss';
import { CustomButton, ModalCustom, SpentRewardBoxItem, SpentRewardGroupBoxItem } from 'components';
import { getSpendingByCategory, getSpendingByGroup, getTotalSpending } from 'services';
import { useAppStore, useAuthStore } from 'store';
import { SpentRecordGroupType, SpentRecordType } from 'types';
import { formatMoney } from 'utils';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import chevLeft from 'assets/images/chevron-left.svg';
import chat from 'assets/images/icon-chat.png';
import iconPre from 'assets/images/back.png';
import iconNext from 'assets/images/next.png';

type activeFilter = 'year' | 'month';

const limit = 6;

export const SpentByCategory = () => {
  const navigate = useNavigate();
  const { userConfig } = useAuthStore();

  const { setAppLoading } = useAppStore();
  const { user } = useAuthStore();

  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeButton, setActiveButton] = useState<activeFilter>('year');
  const [date, setDate] = useState(moment());
  const [spentData, setSpentData] = useState<SpentRecordType[]>([]);
  const [totalSpent, setTotalSpent] = useState('0');
  const [spentBills, setSpentBills] = useState<SpentRecordGroupType[]>([]);
  const [spentSub, setSpentSub] = useState<SpentRecordGroupType[]>([]);

  const [hasMore, setHasMore] = useState(true);
  const [hasMoreSub, setHasMoreSub] = useState(true);
  const [hasMoreBill, setHasMoreBill] = useState(true);

  useEffect(() => {
    if (!user?.hasLinkedPlaid) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [activeButton, date]);

  const fetchData = async () => {
    try {
      setAppLoading(true);
      let params = undefined;
      let paramsGetTotal = undefined;
      if (activeButton === 'month') {
        params = { year: date.year(), month: date.month() + 1, limit: limit, offset: 0 };
        paramsGetTotal = { year: date.year(), month: date.month() + 1 };
      } else {
        params = { limit: limit, offset: 0 };
      }

      const resps = await Promise.all([
        getTotalSpending(paramsGetTotal),
        getSpendingByCategory(params),
        getSpendingByGroup('bill', params),
        getSpendingByGroup('subscription', params)
      ]);

      setSpentBills(resps[2].data || []);
      setSpentSub(resps[3].data || []);
      setTotalSpent(resps[0]?.data?.totalSpending || '0');
      setSpentData(resps[1].data || []);

      if (!resps[1].data || resps[1].data?.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      if (!resps[3].data || resps[3].data?.length < limit) {
        setHasMoreSub(false);
      } else {
        setHasMoreSub(true);
      }
      if (!resps[2].data || resps[2].data?.length < limit) {
        setHasMoreBill(false);
      } else {
        setHasMoreBill(true);
      }
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const fetchDataCategoryMore = async () => {
    try {
      setAppLoading(true);
      let params = undefined;
      if (activeButton === 'month') {
        params = { year: date.year(), month: date.month() + 1, limit: limit, offset: spentData?.length };
      } else {
        params = { limit: limit, offset: spentData?.length };
      }

      const resp = await getSpendingByCategory(params);

      setSpentData([...spentData, ...(resp.data || [])]);

      if (!resp.data || resp.data?.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const fetchDataCategorySubMore = async () => {
    try {
      setAppLoading(true);
      let params = undefined;
      if (activeButton === 'month') {
        params = { year: date.year(), month: date.month() + 1, limit: limit, offset: spentSub?.length };
      } else {
        params = { limit: limit, offset: spentSub?.length };
      }

      const resp = await getSpendingByGroup('subscription', params);

      setSpentSub([...spentSub, ...(resp.data || [])]);

      if (!resp.data || resp.data?.length < limit) {
        setHasMoreSub(false);
      } else {
        setHasMoreSub(true);
      }
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const fetchDataCategoryBillMore = async () => {
    try {
      setAppLoading(true);
      let params = undefined;
      if (activeButton === 'month') {
        params = { year: date.year(), month: date.month() + 1, limit: limit, offset: spentBills?.length };
      } else {
        params = { limit: limit, offset: spentBills?.length };
      }

      const resp = await getSpendingByGroup('bill', params);

      setSpentBills([...spentBills, ...(resp.data || [])]);

      if (!resp.data || resp.data?.length < limit) {
        setHasMoreBill(false);
      } else {
        setHasMoreBill(true);
      }
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleButtonClick = (buttonType: activeFilter) => {
    setActiveButton(buttonType);
    setDate(moment());
  };

  const handleAddMonth = () => {
    if (moment(date).month() === moment().month()) {
      return;
    }
    const temp = moment(date).add(1, 'months');
    setDate(temp);
  };

  const handleSubtractMonth = () => {
    const temp = moment(date).subtract(1, 'months');
    setDate(temp);
  };

  const handleBackBtn = () => {
    navigate('/', { replace: true });
  };

  const handleClickContact = () => {
    navigate(`/contact-us`);
  };

  return (
    <>
      <div className="spent-by-category-container">
        <button className="spent-by-category-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <img className="chat-btn" src={chat} alt="chat" onClick={() => setOpenInfoModal(true)} />
        <div className="spent-by-category-header">
          <h2 className="spent-by-category-header-title">
            You spent{' '}
            <span className="spent-by-category-header-highlight">
              {totalSpent === '0' ? '$--' : `$${formatMoney(totalSpent)}`}
            </span>{' '}
            in {activeButton === 'year' ? <span>last one year</span> : <span> {date.format("MMM'YY")}</span>} on your
            purchases.{' '}
            <span className="spent-by-category-header-infoIcon" onClick={() => setOpenModal(true)}>
              ⓘ
            </span>
          </h2>
        </div>
        <div className="spent-by-category-box">
          <div className="spent-by-category-filter">
            <button
              className={`spent-by-category-filter-left ${activeButton === 'year' ? 'active' : ''}`}
              onClick={() => handleButtonClick('year')}>
              Year
            </button>
            <button
              className={`spent-by-category-filter-right ${activeButton === 'month' ? 'active' : ''}`}
              onClick={() => handleButtonClick('month')}>
              Month
            </button>
          </div>
          {activeButton === 'month' && (
            <div className="spent-by-category-filter--month">
              <img src={iconPre} alt="icon" onClick={handleSubtractMonth} />
              <div className="spent-by-category-filter--month-value">{date.format("MMM'YY")}</div>
              <img
                src={iconNext}
                alt="icon"
                onClick={handleAddMonth}
                className={`${moment(date).month() === moment().month() ? 'maxed' : ''}`}
              />
            </div>
          )}
        </div>
        <div className="spent-by-category-body">
          {spentData?.length === 0 && (
            <div className="text-center mt-5">
              Either there are no purchases in this month, or transactions are no received from your bank.
              <br />
              <br />
              Try refreshing your cards to view updated transactions
            </div>
          )}
          <div className="spent-by-category-wrapper">
            {spentData?.map((item, key) => {
              return (
                <SpentRewardBoxItem
                  data={item}
                  key={key}
                  handleClickItem={() => navigate(`/spent-by-category-detail/${item.category}`)}
                />
              );
            })}
          </div>
        </div>
        {hasMore && spentData?.length !== 0 && (
          <div className="spent-by-category--btn-more" onClick={fetchDataCategoryMore}>
            Load more
          </div>
        )}
        {spentSub?.length !== 0 && (
          <>
            <div className="spent-by-category--title">Your Subscriptions</div>
            <div className="spent-by-category-body">
              <div className="spent-by-category-wrapper">
                {spentSub?.map((item, key) => {
                  return (
                    <SpentRewardGroupBoxItem
                      data={item}
                      key={key}
                      handleClickItem={() => navigate(`/spent-by-merchant-detail/Subscription/${item.mappedMerchant}`)}
                    />
                  );
                })}
              </div>
            </div>
            {hasMoreSub && (
              <div className="spent-by-category--btn-more" onClick={fetchDataCategorySubMore}>
                Load more
              </div>
            )}
          </>
        )}
        {spentBills?.length !== 0 && (
          <>
            <div className="spent-by-category--title">Your Bills</div>
            <div className="spent-by-category-body">
              <div className="spent-by-category-wrapper">
                {spentBills?.map((item, key) => {
                  return (
                    <SpentRewardGroupBoxItem
                      data={item}
                      key={key}
                      handleClickItem={() => navigate(`/spent-by-merchant-detail/Bills/${item.mappedMerchant}`)}
                    />
                  );
                })}
              </div>
            </div>
            {hasMoreBill && (
              <div className="spent-by-category--btn-more" onClick={fetchDataCategoryBillMore}>
                Load more
              </div>
            )}
          </>
        )}
      </div>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        onClose={() => setOpenModal(!openModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Spent History</div>
          <div>
            This view captures what you spent by category in last 12 months based on your linked accounts.
            <br />
            <br />
            For your convenience, your purchases are broken into key spend categories - wherein you can view trending of
            your spend, top transactions as well as best card you should use for these categories.
            <br />
            <br />
            Your recurring bills and subscriptions are also separately captured to give you an overall view of where
            your dollars are being spent.
            <br />
            <br />
            You will gradually see comprehensive insights to stay on top of your finances, to be rolled out shortly.
          </div>
        </div>
        <CustomButton onClick={() => setOpenModal(!openModal)}>Close</CustomButton>
      </ModalCustom>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openInfoModal}
        toggle={() => setOpenInfoModal(!openInfoModal)}
        onClose={() => setOpenInfoModal(!openInfoModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Have a question?</div>
          <div>
            Get help from experts at Uthrive. Typically responds in 2-5 hrs.
            <br />
            <br /> For urgent help, please call <span className="highlight">{userConfig?.callContactNumber}</span>{' '}
            {userConfig?.callContactTime}.
          </div>
        </div>
        <CustomButton onClick={handleClickContact}>Start a chat</CustomButton>
      </ModalCustom>
    </>
  );
};
