import { ModalCustom } from 'components/ModalCustom';
import './style.scss';
import chat from 'assets/images/chat.png';
import gift from 'assets/images/gift.png';
import cardIcon from 'assets/images/plaid_intro_3.png';
import secureIcon from 'assets/images/secure.png';

type props = {
  onClose: () => void;
  isOpen: boolean;
  handleConfirm: () => void;
};

const contents = [
  {
    title: 'No more missed savings.',
    subtitle: 'View rewards you are not earning by using wrong cards.',
    icon: gift
  },
  { title: 'Get personalized advice.', subtitle: 'Know how to maximize savings on what you spend anyway.', icon: chat },
  {
    title: 'Use the right cards.',
    subtitle: 'Easily find your best card to use in-store or offline to maximize savings.',
    icon: cardIcon
  }
];

export const ModalLinkPlaid = ({ onClose, isOpen, handleConfirm }: props) => {
  return (
    <ModalCustom onClose={onClose} toggle={onClose} isOpen={isOpen} className="modal-blur modal-sm modal-link-plaid">
      <div className="modal-link-plaid">
        <div className="modal-link-plaid--title">Let&apos;s get all your savings and rewards.</div>
        <div className="modal-link-plaid--divider" />
        <div className="modal-link-plaid--text mb-4">
          Simply link your credit cards and checking accounts to get started,
        </div>
        {contents.map((e, idx) => {
          return (
            <div className="modal-link-plaid--item" key={idx}>
              <div className="modal-link-plaid--item-img">
                <img src={e.icon} alt="icon" />
              </div>

              <div>
                <div className="modal-link-plaid--item-title">{e.title}</div>
                <div className="modal-link-plaid--item-subtitle">{e.subtitle}</div>
              </div>
            </div>
          );
        })}

        <div className="modal-link-plaid--footer">
          <img src={secureIcon} alt="icon" />
          <div className="modal-link-plaid--text">
            Link your accounts securely.
            <br />
            the way millions of other users do.
          </div>
        </div>

        <div className="modal-link-plaid--btns mt-5">
          <button className="modal-link-plaid--btn background" onClick={handleConfirm}>
            Link your accounts
          </button>
        </div>
      </div>
    </ModalCustom>
  );
};
