import { NotValidateCardCardRecordType } from 'types';
import iconExclamation from 'assets/images/exclamation.png';
import cardImg from 'assets/images/card-default-icon.png';
import checkIcon from 'assets/images/green-check-icon.svg';
import './style.scss';
import { useMemo } from 'react';

type props = {
  data: NotValidateCardCardRecordType;
  handleClick?: () => void;
  isValidatePage?: boolean;
  className?: string;
};

export const NotValidateCard = ({ data, handleClick, isValidatePage = false, className = '' }: props) => {
  const renderCardName = useMemo(() => {
    if (data?.linkStatus === 'PENDING') {
      return data?.otherCardUserPrompt;
    } else if (data?.linkStatus === 'VALIDATED') {
      return `${data?.mappedCard} ${data?.accountMask ? '- ' + data?.accountMask : ''}`;
    } else {
      return `${data?.institutionName || data?.institutionShortName} ${data?.accountSubtype} ${data?.accountMask ? '- ' + data?.accountMask : ''}`;
    }
  }, [data]);

  return (
    <div className={`not-validate-linked-card ${className}`}>
      {data?.linkedStatus === 'P' && (
        <div className="not-validate-linked-card--exclamation">
          <img src={iconExclamation} alt="Exclamation" />
        </div>
      )}
      <div className="not-validate-linked-card--info">
        <img src={data.cardImage || data?.cardImg || cardImg} alt="card" />
        <div>{renderCardName}</div>
      </div>
      <div className="not-validate-linked-card--action">
        {!isValidatePage ? (
          <div onClick={handleClick ? handleClick : undefined}>
            {data?.linkedStatus === 'P'
              ? 'Pending Premium'
              : data.linkStatus === 'NOT_VALIDATED'
                ? 'Validate'
                : 'Pending'}
          </div>
        ) : (
          <div
            onClick={
              handleClick && (data.linkStatus === 'NOT_VALIDATED' || data.linkStatus === 'PENDING')
                ? handleClick
                : undefined
            }>
            {data.linkStatus === 'NOT_VALIDATED' ? (
              'Validate'
            ) : data.linkStatus === 'PENDING' ? (
              'Pending'
            ) : (
              <img src={checkIcon} alt="check" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
