import { ModalCustom } from 'components/ModalCustom';
import './style.scss';
import { get } from 'lodash';

type props = {
  data: any;
  onClose: () => void;
  isOpen: boolean;
  handleClickBtn2?: () => void;
};

export const ModalPlaidValidate = ({ data, onClose, isOpen, handleClickBtn2 }: props) => {
  const { title, content, type, textButton2 = null } = data;
  return (
    <ModalCustom onClose={onClose} isOpen={isOpen} className="modal-blur modal-lg modal-plaid-validate">
      <div className="modal-plaid-validate-error">
        <div className="modal-plaid-validate--title">{title}</div>
        <div className="modal-plaid-validate--text" dangerouslySetInnerHTML={{ __html: content }} />
        <div className="modal-plaid-validate--btns">
          <button className="modal-plaid-validate--btn" onClick={onClose}>
            {get(
              data,
              'textButton',
              type === 1 ? 'Link other accounts' : type === 0 ? 'Add more cards' : 'Get Uthrive Premium'
            )}
          </button>
          {textButton2 && (
            <button className="modal-plaid-validate--btn" onClick={handleClickBtn2}>
              {textButton2}
            </button>
          )}
        </div>
      </div>
    </ModalCustom>
  );
};
