import React, { useState, useEffect, useRef, ChangeEvent, FocusEvent } from 'react';
import { Auth } from 'aws-amplify';
import ReCAPTCHA from 'react-google-recaptcha';
import { ModalCustom } from 'components/ModalCustom';
import { SignUpStep2Header } from 'components/SignUpStep2Header';
import { validEmailRegex } from 'utils';
import logo from 'assets/images/logo.svg';
import './style.scss';
import { getUserByEmail } from 'services';
import { CustomButton } from 'components/Button';
import { useAppStore, useAuthStore } from 'store';
import { OtpInput } from 'components/OtpInput';
import { accountNotFound, errTextOtp, invalidEmail, newCodeSent, unknowErr } from 'const';
import { useNavigate } from 'react-router-dom';

type props = {
  isOpen: boolean;
  toggle: () => void;
  handleClickSignup: () => void;
};

export const ModalLogin = ({ isOpen = false, toggle, handleClickSignup }: props) => {
  const { setToken, browserLocation, resetBrowserLocation } = useAuthStore();
  const navigate = useNavigate();
  const { setAppLoading } = useAppStore();
  const [messageQuicklyResend, setMessageQuicklyResend] = useState('');

  const [userCognito, setUserCognito] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);

  const [seconds, setSeconds] = useState(0);
  const [errEmail, setErrEmail] = useState('');
  const [errOTP, setErrOTP] = useState('');

  const captchaRef = useRef<ReCAPTCHA>(null);

  const [signInStep, setSignInStep] = useState(1);

  useEffect(() => {
    setErrOTP('');
    setErrEmail('');
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const checkValidEmail = (e: FocusEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    if (!validEmailRegex?.test(value)) {
      setErrEmail(invalidEmail);
      setIsValidEmail(false);
    } else {
      getUserByEmail({ email: email })
        .then((res) => {
          if (res.data.shadow_status) {
            setErrEmail('');
            setIsValidEmail(true);
          } else {
            setIsValidEmail(false);
            setErrEmail(accountNotFound);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsValidEmail(false);
          setErrEmail(accountNotFound);
        });
    }
  };

  const handleChangeOTP = async (value: string) => {
    setOtp(value);
  };

  const handleConfirmOTP = async () => {
    try {
      setAppLoading(true);
      const awsUserVerified = await Auth.sendCustomChallengeAnswer(userCognito, otp);

      if (awsUserVerified && awsUserVerified?.signInUserSession) {
        const token = awsUserVerified?.signInUserSession?.accessToken?.jwtToken;
        setToken(token);
        const refreshToken = awsUserVerified?.signInUserSession?.refreshToken?.getToken();
        localStorage.setItem('refreshToken', refreshToken);
        const deviceKey = awsUserVerified?.signInUserSession?.accessToken?.payload?.device_key;
        localStorage.setItem('deviceKey', deviceKey);
        onCloseModal();

        if (browserLocation && browserLocation?.pathname !== '') {
          navigate(`${browserLocation.pathname}${browserLocation?.search}`);
          resetBrowserLocation();
        } else {
          navigate('/');
        }
      } else {
        setErrOTP(errTextOtp);
      }
      setAppLoading(false);
    } catch (error) {
      console.log(error);
      setErrOTP(errTextOtp);
      setAppLoading(false);
    }
  };

  const onHandleNextStep = async () => {
    try {
      setAppLoading(true);
      setIsLoading(true);
      const resp = await Auth.signIn(email);
      console.log('resp: ', resp);
      setUserCognito(resp);
      setAppLoading(false);
      setIsLoading(false);
      setSignInStep(signInStep + 1);
    } catch (err) {
      console.log('err: ', err);
      setAppLoading(false);
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setSignInStep(1);
    setMessageQuicklyResend('');
    setOtp('');
    setErrOTP('');
  };

  const resendOtp = async () => {
    try {
      if (seconds === 0) {
        setIsLoading(true);
        setSeconds(60);
        setErrOTP('');
        const userCognitoResend = await Auth.signIn(email);
        setMessageQuicklyResend(newCodeSent);
        setUserCognito(userCognitoResend);
        setIsLoading(false);
      } else {
        setMessageQuicklyResend(`Too quick! Please wait for ${seconds} seconds before clicking ‘Resend’`);
      }
    } catch (err) {
      console.log(err);
      setMessageQuicklyResend(unknowErr);
    }
  };

  const clearState = () => {
    setEmail('');
    setErrEmail('');
    setErrOTP('');
    setMessageQuicklyResend('');
    setSignInStep(1);
    setOtp('');
    setIsValidEmail(false);
    setUserCognito({});
    setIsLoading(false);
    setSeconds(0);
  };

  const onClickSignUp = () => {
    handleClickSignup();
    clearState();
  };

  const onCloseModal = () => {
    clearState();
    toggle();
  };

  return (
    <>
      <ModalCustom
        isOpen={isOpen}
        className="modal-blur modal-lg modal-login-account"
        toggle={onCloseModal}
        onClose={onCloseModal}
        isSupportClose>
        <div>
          <img src={logo} alt="logo" />
        </div>
        {signInStep === 1 && (
          <div className="modal-container">
            <div className="title-container">
              <h1 className="title">Welcome! Sign in to your account.</h1>
              <div className="subTitle">
                Don&apos;t have an account?{' '}
                <span className="text-link" onClick={onClickSignUp}>
                  Sign up here.
                </span>
              </div>
            </div>
            <div className="form-container-stp1">
              <div className="notice-text mt-5">For your convenience we’ll send 6-digit code.</div>
              <div className="pb-2 pt-3">
                <div className="email-input-wrapper">
                  <div className="email-input-container">
                    <input value={email} onChange={handleChangeEmail} onBlur={checkValidEmail} />
                    {email && isValidEmail && (
                      <img
                        className="validate-icon"
                        src="https://uthrive-resources.s3.amazonaws.com/extension_assets/field-validation-min.png"
                        alt="icon"
                      />
                    )}
                  </div>
                  <label>* Your email address (required)</label>
                  {errEmail && <div className="err-input">{errEmail}</div>}
                </div>
              </div>
            </div>
            <div className="modal-btn-group">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPCHA_SITE_KEY || ''}
                hl="en"
                onChange={onHandleNextStep}
                size="invisible"
                ref={captchaRef}
                className="re-captcha-container"
              />
              <CustomButton
                className="btn-confirm"
                disabled={!email || isLoading || !isValidEmail}
                onClick={() => captchaRef.current?.execute()}>
                Continue
              </CustomButton>
            </div>
          </div>
        )}
        {signInStep === 2 && (
          <>
            <div className="screen-code">
              <SignUpStep2Header
                handleBack={handleBack}
                isLoading={isLoading}
                signUpData={{ phone_number_format: 'phone' }}
              />
              <div className="otp-wrapper">
                <div>
                  <OtpInput value={otp} handleChage={handleChangeOTP} />
                  <div className="otp-resend">
                    Didn’t get the code?{' '}
                    <span className="text-link" onClick={resendOtp}>
                      Resend.
                    </span>
                  </div>
                  <div className="err-input">{messageQuicklyResend}</div>
                  <div className="err-input">{errOTP}</div>
                </div>
              </div>
            </div>
            <CustomButton disabled={otp?.length < 6} onClick={handleConfirmOTP}>
              Continue
            </CustomButton>
          </>
        )}
      </ModalCustom>
    </>
  );
};
