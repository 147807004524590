import { ManualCardRecordType } from 'types';
import './style.scss';

type props = {
  data: ManualCardRecordType;
  handleClick?: () => void;
  className?: string;
};

export const ManualCard = ({ data, handleClick, className = '' }: props) => {
  return (
    <div className={`manual-card ${className}`}>
      <div className="manual-card--info">
        <img src={data.cardImage} alt="card" />
        <div>{data.mapped_card}</div>
      </div>
      <div className="manual-card--action">
        <div onClick={handleClick ? handleClick : undefined}>
          {data.addedAccountStatus !== 'PENDING' ? 'Link' : data.addedAccountStatus}
        </div>
      </div>
    </div>
  );
};
