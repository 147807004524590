import { ParamsVerifyInfoType, RegisterParamsType, UserDataType, VerifyUserParamsType } from 'types';
import axiosClient from './axiosClient';
import { endpoints } from './endpoints';

const URl_register = endpoints.register;
const URl_register_verify = endpoints.registerVerify;
const URl_verify_cognito_user = endpoints.verifyCognitoUser;
const URl_get_user_by_email = endpoints.getUserByEmail;
const URl_get_me = endpoints.getMe;
const URl_register_social = endpoints.registerSocial;

export const register = async (params: RegisterParamsType) => {
  const resp = await axiosClient.post(URl_register, {
    ...params,
    deviceType: 'web'
  });
  return resp.data;
};

export const verifyUser = async (params: VerifyUserParamsType) => {
  const resp = await axiosClient.post(URl_register_verify, params);
  return resp.data;
};

export const verifyCognitoUser = async (token: string) => {
  const resp = await axiosClient.get(URl_verify_cognito_user, { headers: { Authorization: 'Bearer ' + token } });
  return resp.data;
};

export const getUserByEmail = async (params: { email: string }) => {
  const resp = await axiosClient.get(URl_get_user_by_email, { params });
  return resp.data;
};

export const getMe = async (): Promise<UserDataType> => {
  const resp = await axiosClient.get(URl_get_me);
  return resp.data;
};

export const checkingSoicalAccNotFound = async (token: string): Promise<UserDataType> => {
  const resp = await axiosClient.get(URl_get_me, { headers: { Authorization: 'Bearer ' + token } });
  return resp.data;
};

export const registerSocial = async (params: RegisterParamsType, token: string, deviceType = 'web') => {
  const resp = await axiosClient.post(
    URl_register_social,
    {
      ...params,
      deviceType: deviceType
    },
    { headers: { Authorization: 'Bearer ' + token } }
  );
  return resp.data;
};

export const getProfile = async () => {
  const resp = await axiosClient.get(endpoints.getProfile);
  return resp.data;
};

export const getUserConfig = async () => {
  const resp = await axiosClient.get(endpoints.userConfig);
  return resp.data;
};

export const sendOtpDeleteAccount = async () => {
  const resp = await axiosClient.get(endpoints.sendOTP);
  return resp.data;
};

export const submitOtpDeleteAccount = async (params: { OTP: string; feedback: string }) => {
  const resp = await axiosClient.delete(endpoints.deleteAccount, { data: params });
  return resp.data;
};

export const verifyUserInfo = async (params: ParamsVerifyInfoType) => {
  const resp = await axiosClient.put(endpoints.verifyUserInfo, params);
  return resp.data;
};

export const consentCaptureSocial = async (token: string) => {
  const resp = await axiosClient.post(endpoints.consentCapture, {}, { headers: { Authorization: 'Bearer ' + token } });
  return resp.data;
};
