import chat from 'assets/images/icon-chat.png';
import './style.scss';
import {
  EarnedChart,
  LatestOffers,
  RecommendedCards,
  SpendCalculator,
  ModalCustom,
  CustomButton,
  PopupNotify
} from 'components';
import { useEffect, useState } from 'react';
import { useAppStore, useAuthStore, useCardsStore } from 'store';
import { getAvailableRewards, getCardAll, getLatestOffers, getRewardsSummary, getTotalSpending } from 'services';
import { latestOfferType } from 'types';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reactiveMessage, renewStatusMessage } from 'const';

const filters = [
  { name: 'W', value: 'w' },
  { name: 'M', value: 'm' },
  { name: 'Y', value: 'y' }
];

export const Home = () => {
  const navigate = useNavigate();
  const params = useLocation();

  const { user, userConfig } = useAuthStore();
  const { setAppLoading, rewardsSummary, setRewardsSummary } = useAppStore();
  const { featuredCards, setFeaturedCards } = useCardsStore();

  const [filter, setFilter] = useState(filters[0].value);
  const [totalSpending, setTotalSpending] = useState(0);
  const [availableRewards, setAvailableRewards] = useState(0);
  const [annualValue, setAnnualValue] = useState(1500);
  const [latestOffers, setLatestOffers] = useState<latestOfferType[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const isShowNotify = (user?.isReactive || user?.isRenewConnection?.status) && showNotification;
  const title = user?.isReactive ? reactiveMessage : renewStatusMessage;

  useEffect(() => {
    const lastDismissed = localStorage.getItem('notificationDismissedTime');
    if (lastDismissed) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - parseInt(lastDismissed, 10);
      if (timeDifference >= 86400000) {
        setShowNotification(true);
      }
    } else {
      setShowNotification(true);
    }
  }, []);

  useEffect(() => {
    if (!params?.search) return;
    const queryParams = new URLSearchParams(params?.search);
    const mappedCardId = queryParams.get('mappedCardId');
    const screenName = queryParams.get('screenName');
    const mappedCategory = queryParams.get('mappedCategory');
    const isYourCard = queryParams.get('isYourCard') || false;
    console.log('screenName: ', screenName);
    switch (screenName) {
      case 'recommended-card':
        if (!mappedCardId) return;
        navigate(`/cards/${mappedCardId}`);
        return;
      case 'MissedRewardDetailScreen':
        navigate(`/rewards-missed-detail/${mappedCategory}?isYourCard=${isYourCard}`);
        return;

      default:
        return;
    }
  }, [params]);

  useEffect(() => {
    fetchHomeData();
  }, []);

  const fetchHomeData = async () => {
    try {
      setAppLoading(true);
      const resp = await Promise.all([
        getTotalSpending(),
        getAvailableRewards(),
        getRewardsSummary(),
        getLatestOffers(),
        getCardAll()
      ]);
      setTotalSpending(resp[0]?.data?.totalSpending || 0);
      setAvailableRewards(resp[1]?.data?.totalRewardBalance || 0);
      setRewardsSummary({ earnedTotal: resp[2]?.earnedTotal || 0, missedTotal: resp[2]?.missedTotal || 0 });
      setLatestOffers(resp[3]?.data || []);
      setFeaturedCards(resp[4]?.data || []);
      setAppLoading(false);
    } catch (err: any) {
      console.log(err);
      toast.error(err?.response?.data?.message || 'Something went wrong');
      setAppLoading(false);
    }
  };

  const handleSelectFilter = (value: string) => {
    setFilter(value);
  };

  const handleClickSeeAllCard = () => {
    navigate('/cards');
  };

  const handleClickLinkAccount = () => {
    navigate('/linked-account', { state: { openPlaid: true } });
  };

  const handleChangeSlide = (value: number) => {
    setAnnualValue(value);
  };

  const handleClickSeeAllOffers = () => {
    navigate('/offers');
  };

  const handleClickOffer = (offerMerchant: string) => {
    navigate('/offers', { state: { offerMerchantState: offerMerchant } });
  };

  const handleClickCard = (id: number) => {
    navigate(`/cards/${id}`);
  };

  const handleClickContact = () => {
    navigate(`/contact-us`);
  };

  const handleNo = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem('notificationDismissedTime', currentTime.toString());
    setShowNotification(false);
  };

  return (
    <>
      {isShowNotify && (
        <div className="home-popup-wrapper">
          <PopupNotify title={title} id="home-id" onClickNo={handleNo} onClickYes={() => navigate('linked-account')} />
        </div>
      )}
      <div className="home-container">
        <div className="home-header-container mobile">
          <div className="home-header-title-container">
            <div className="home-header-title">
              {user?.hasLinkedPlaid ? (
                <>
                  <span>Hi {user?.firstName || 'there'}</span>,<br /> Your rewards & savings summary.
                </>
              ) : (
                <>
                  <span>Hi {user?.firstName || 'there'}</span>,<br /> Link your accounts to see your spending data.
                </>
              )}
            </div>
            <img src={chat} alt="chat" />
          </div>
          <div className="home-filter-container d-none">
            {filters.map((f) => {
              return (
                <div
                  key={f.value}
                  className={`home-filter-item ${f.value === filter && 'selected'}`}
                  onClick={() => handleSelectFilter(f.value)}>
                  {f.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="home-body">
          <div className="home-offers-container">
            <div className="home-col-1">
              <div className="home-header-container desktop">
                <div className="home-header-title-container">
                  <div className="home-header-title">
                    {user?.hasLinkedPlaid ? (
                      <>
                        <span>Hi {user?.firstName || 'there'}</span>,<br /> Your rewards & savings summary.
                      </>
                    ) : (
                      <>
                        <span>Hi {user?.firstName || 'there'}</span>,<br /> Use slider to view rewards you might be
                        missing.
                      </>
                    )}
                  </div>
                  <div className="home-filter-container d-none">
                    {filters.map((f) => {
                      return (
                        <div
                          key={f.value}
                          className={`home-filter-item ${f.value === filter && 'selected'}`}
                          onClick={() => handleSelectFilter(f.value)}>
                          {f.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <img src={chat} alt="chat" onClick={() => setOpenModal(true)} />
              </div>
              {user?.hasLinkedPlaid ? (
                <EarnedChart
                  isYear
                  spent={totalSpending}
                  availableRewards={availableRewards}
                  rewardsSummary={rewardsSummary}
                />
              ) : (
                <>
                  <SpendCalculator
                    value={annualValue}
                    handleChangeSlide={handleChangeSlide}
                    handleClickLinkAccount={handleClickLinkAccount}
                  />
                </>
              )}
            </div>
            <div className={`home-col-2 ${user?.hasLinkedPlaid ? '' : 'hasLinked'}`}>
              <LatestOffers
                handleClickSeeAll={handleClickSeeAllOffers}
                data={latestOffers}
                handleClickOffer={handleClickOffer}
              />
            </div>
          </div>
          {featuredCards?.length > 0 && (
            <div className="home-recommended-cards">
              <RecommendedCards
                handleClickSeeAll={handleClickSeeAllCard}
                data={featuredCards}
                handleClickItem={handleClickCard}
              />
            </div>
          )}
        </div>

        <ModalCustom
          className="modal-annual-reward-note"
          isOpen={openModal}
          toggle={() => setOpenModal(!openModal)}
          onClose={() => setOpenModal(!openModal)}>
          <div className="modal-annual-reward-note-container">
            <div className="modal-annual-reward-note--title">Have a question?</div>
            <div>
              Get help from experts at Uthrive. Typically responds in 2-5 hrs.
              <br />
              <br /> For urgent help, please call <span className="highlight">
                {userConfig?.callContactNumber}
              </span>{' '}
              {userConfig?.callContactTime}.
            </div>
          </div>
          <CustomButton onClick={handleClickContact}>Start a chat</CustomButton>
        </ModalCustom>
      </div>
    </>
  );
};
