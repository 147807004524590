import { ModalCustom } from 'components/ModalCustom';
import './style.scss';
import { LinkedCardRecordType } from 'types';
import cardDefault from 'assets/images/card-default-icon.png';

type props = {
  data: LinkedCardRecordType | null;
  onClose: () => void;
  isOpen: boolean;
  handleConfirm: () => void;
};

export const ModalUnlinkCard = ({ data, onClose, isOpen, handleConfirm }: props) => {
  if (!data) return null;
  return (
    <ModalCustom onClose={onClose} toggle={onClose} isOpen={isOpen} className="modal-blur modal-sm modal-unlink-card">
      <div className="modal-unlink-card">
        <div className="modal-unlink-card--title">Unlink your card</div>
        <div className="modal-unlink-card--text">{`Do you want to unlink ${data?.mapped_card} from Uthrive?`}</div>
        <div className="modal-unlink-card--card-container">
          <img src={data?.cardImage || cardDefault} alt="card" />
          <div>{`${data?.institutionName} - ${data?.accountMask}`}</div>
        </div>
        <div className="modal-unlink-card--text">
          {`Please note, unlinking of an\naccount is `}
          <span>final</span>
          {` and can’t be reversed. You may not be able to link this account again.`}
        </div>
        <div className="modal-unlink-card--btns mt-5">
          <button className="modal-unlink-card--btn background" onClick={handleConfirm}>
            Confirm
          </button>
          <button className="modal-unlink-card--btn" onClick={onClose}>
            Back
          </button>
        </div>
      </div>
    </ModalCustom>
  );
};
