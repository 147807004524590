import { ModalCustom } from 'components/ModalCustom';
import './style.scss';
import { ExistingAccountType } from 'types';
import cardDefault from 'assets/images/card-default-icon.png';
import checkIcon from 'assets/images/green-check-icon.svg';
import questionIcon from 'assets/images/question-mark-circle.png';

type props = {
  data: ExistingAccountType | null;
  onClose: () => void;
  isOpen: boolean;
  handleConfirm: () => void;
};

export const ModalExistingCardMapping = ({ data, onClose, isOpen, handleConfirm }: props) => {
  if (!data) return null;
  return (
    <ModalCustom onClose={onClose} isOpen={isOpen} className="modal-blur modal-sm modal-existing-card-mapping">
      <div className="modal-existing-card-mapping">
        <div className="modal-existing-card-mapping--title">Repeat card</div>
        <div className="modal-existing-card-mapping--subtitle mb-1">This card is already linked</div>
        <div className="modal-existing-card-mapping--text">
          {`\nThere is another ${data?.pendingLinkedAccount?.mappedCard} card in your linked accounts. Are you sure you have multiple of these cards? `}
        </div>
        <div className="modal-existing-card-mapping--card-container mt-3 mb-3 red-border">
          <div className="modal-existing-card-mapping--card-info">
            {`${data?.pendingLinkedAccount?.institutionShortName} ${data?.pendingLinkedAccount?.accountSubtype} - ${data?.pendingLinkedAccount?.accountMask}`}
          </div>
          <div className="modal-existing-card-mapping--card-image">
            <img src={data?.pendingLinkedAccount?.cardImg || cardDefault} alt="card" />
            <div className="modal-existing-card-mapping--card-status">
              <div className="modal-existing-card-mapping--card-status-text">
                <img src={questionIcon} alt="question" />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-existing-card-mapping--btns mt-5">
          <button className="modal-existing-card-mapping--btn" onClick={handleConfirm}>
            Confirm
          </button>
          <button className="modal-existing-card-mapping--btn" onClick={onClose}>
            No, take me back
          </button>
        </div>
        <div className="modal-existing-card-mapping--divider"></div>
        <div className="modal-existing-card-mapping--subtitle">Your linked card</div>
        {data?.existLinkedAccounts?.map((c, idx) => {
          return (
            <div className="modal-existing-card-mapping--card-container mt-3 mb-3" key={idx}>
              <div className="modal-existing-card-mapping--card-info">{`${c?.mappedCard} - ${c?.accountMask}`}</div>
              <div className="modal-existing-card-mapping--card-image">
                <img src={c?.cardImg || cardDefault} alt="card" />
                <div className="modal-existing-card-mapping--card-status">
                  {c.linkStatus !== 'PENDING' && c.linkStatus !== 'NOT_VALIDATED' ? (
                    <img src={checkIcon} alt="check" />
                  ) : (
                    <div className="modal-existing-card-mapping--card-status-text">
                      {c.linkStatus !== 'PENDING' ? 'Pending' : 'Validate'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </ModalCustom>
  );
};
