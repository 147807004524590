import { CustomButton } from 'components/Button';
import { ModalCustom } from 'components/ModalCustom';
import './style.scss';

type props = {
  isOpen: boolean;
  onClose: () => void;
  cardName: string;
  onClickBtn: () => void;
};

export const ModalPendingValidate = ({ isOpen, onClose, cardName, onClickBtn }: props) => {
  return (
    <ModalCustom isOpen={isOpen} isSupportClose onClose={onClose}>
      <div className="pending-validation">
        <div className="pending-validation-title mb-4">{cardName} is pending for validation</div>
        <div className="pending-validation-subtitle">Your account is being validated by Uthrive.</div>
        <div className="pending-validation-subtitle">There is no action needed from you.</div>
        <div className="pending-validation-subtitle mt-3">Do you want to edit your selection?</div>
        <CustomButton onClick={onClickBtn}>Edit</CustomButton>
      </div>
    </ModalCustom>
  );
};
