import { Input } from 'reactstrap';
import { MappingCardType } from 'types';
import './style.scss';

type props = {
  data: MappingCardType;
  isCheck?: boolean;
  handleCheck?: () => void;
};

export const MappingCard = ({ data, isCheck = false, handleCheck }: props) => {
  return (
    <div className="mapping-card-container" onClick={handleCheck ? handleCheck : undefined}>
      <Input type="radio" checked={isCheck} readOnly />
      <img src={data.cardImg} alt="card" />
      <div className="mapping-card-container--name">{data.mappedCard}</div>
    </div>
  );
};
