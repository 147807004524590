import './style.scss';
import { useState } from 'react';

export const OfferHowItWork = () => {
  const [showHowItWork, setShowHowItWork] = useState(false);

  return (
    <div className="offer-how-it-work">
      <div className="offer-how-it-work--header">
        <div className="offer-how-it-work--header-title" onClick={() => setShowHowItWork(!showHowItWork)}>
          How it works?
        </div>
        {showHowItWork && (
          <div className="offer-how-it-work--header-close" onClick={() => setShowHowItWork(!showHowItWork)}>
            X
          </div>
        )}
      </div>
      {showHowItWork && (
        <div className="offer-how-it-work--content">
          <div className="offer-how-it-work--content-item">
            <div className="offer-how-it-work--content-item-title">1</div>
            <div className="offer-how-it-work--content-item-description">
              Simply click on &apos;Get Offer&apos; to visit merchant site and make purchases as usual.
            </div>
          </div>
          <div className="offer-how-it-work--content-item">
            <div className="offer-how-it-work--content-item-title">2</div>
            <div className="offer-how-it-work--content-item-description">
              You will get cash back from Uthrive on qualifying purchase amount, which you can redeem into your checking
              or savings account via PayPal.
            </div>
          </div>
          <div className="offer-how-it-work--content-item">
            <div className="offer-how-it-work--content-item-title">3</div>
            <div className="offer-how-it-work--content-item-description">
              You will earn this cash back, in addition to any rewards offered by your credit card or debit card.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
