import { PlaidStoreType } from 'types';
import { create } from 'zustand';

const initState = {
  progress: 0,
  showProgress: false,
  showPlaidLoader: false
};

export const usePlaidStore = create<PlaidStoreType>((set) => ({
  progress: 0,
  showProgress: false,
  showPlaidLoader: false,
  setProgress: (value) =>
    set(() => {
      return { progress: value };
    }),
  setShowProgress: (value) =>
    set(() => {
      return { showProgress: value };
    }),
  setShowPlaidLoader: (value) =>
    set(() => {
      return { showPlaidLoader: value };
    }),
  reset: () => set({ ...initState })
}));
