import { useState, useEffect, useRef, FocusEvent, ChangeEvent } from 'react';
import { ModalCustom } from 'components/ModalCustom';
import { validEmailRegex, validatePhoneNumber } from 'utils';
import ReCAPTCHA from 'react-google-recaptcha';
import { Auth } from 'aws-amplify';
import { PatternFormat } from 'react-number-format';
import { register, verifyCognitoUser, verifyUser } from 'services';
import { SignUpStep1Header } from 'components/SignUpStep1Header';
import { SignUpStep2Header } from 'components/SignUpStep2Header';
import { CustomButton } from 'components/Button';
import './style.scss';
import {
  emailAlreadyExist,
  errTextOtp,
  expiredCode,
  invalidEmail,
  invalidPhone,
  newCodeSent,
  phoneAlreadyExist,
  unknowErr
} from 'const';
import { useAppStore, useAuthStore } from 'store';
import logo from 'assets/images/logo.svg';
import { OtpInput } from 'components/OtpInput';
import { useNavigate } from 'react-router-dom';
import { FormGroup, Input, Label } from 'reactstrap';

type props = {
  toggle: () => void;
  className?: string;
  isOpen: boolean;
  handleClickSignIn: () => void;
};

export const ModalSignUp = (props: props) => {
  const { isOpen, toggle, handleClickSignIn } = props;

  const navigate = useNavigate();
  const { setAppLoading } = useAppStore();
  const { setToken } = useAuthStore();

  const captchaRef = useRef<ReCAPTCHA>(null);

  const [signUpStep, setSignUpStep] = useState(1);
  const [messageQuicklyResend, setMessageQuicklyResend] = useState('');

  const [userCognito, setUserCognito] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [errPhone, setErrPhone] = useState('');
  const [signUpUser, setSignUpUser] = useState({
    shadow_status: null
  });
  const [expiredOtp, setExpiredOtp] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [errEmail, setErrEmail] = useState('');
  const [errOTP, setErrOTP] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (expiredOtp > 0) {
        setExpiredOtp(expiredOtp - 1);
      }

      if (expiredOtp === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const checkValidPhone = async (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target?.value;

    const numberConvert = '+1' + value.replace(/-/gi, '');
    const isValid = validatePhoneNumber(value);
    const isValidFromApi = await verifyUser({
      phone_number: numberConvert,
      email: 'email@gmail.com'
    });
    if (!isValid) {
      setIsValidPhone(false);
      setErrPhone(invalidPhone);
    } else {
      if (isValidFromApi?.data?.validPhone) {
        setErrPhone('');
        setIsValidPhone(true);
      } else {
        setErrPhone(phoneAlreadyExist);
        setIsValidPhone(false);
      }
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const checkValidEmail = async (e: FocusEvent<HTMLInputElement>) => {
    const value = e?.target?.value;

    if (!validEmailRegex?.test(value)) {
      setIsValidEmail(false);
      setErrEmail(invalidEmail);
    } else {
      try {
        const verifyResp = await verifyUser({ email: value });
        console.log(verifyResp);
        if (verifyResp && verifyResp?.data?.validEmail) {
          setSignUpUser({
            shadow_status: verifyResp?.data?.shadowStatus
          });
          setIsValidEmail(true);
          setErrEmail('');
        } else {
          setIsValidEmail(false);
          setErrEmail(emailAlreadyExist);
        }
      } catch (err) {
        console.log(err);
        setErrEmail(unknowErr);
      }
    }
  };

  const handleChangeOTP = async (value: string) => {
    setOtp(value);
    if (expiredOtp === 0 && value?.length === 6) {
      setErrOTP(expiredCode);
      return;
    }
  };

  const handleConfirmOTP = async () => {
    try {
      setAppLoading(true);
      const awsUserVerified = await Auth.sendCustomChallengeAnswer(userCognito, otp);
      if (awsUserVerified) {
        const token = awsUserVerified?.signInUserSession?.accessToken?.jwtToken;
        setToken(token);
        const refreshToken = awsUserVerified?.signInUserSession?.refreshToken?.getToken();
        localStorage.setItem('refreshToken', refreshToken);
        const deviceKey = awsUserVerified?.signInUserSession?.accessToken?.payload?.device_key;
        localStorage.setItem('deviceKey', deviceKey);
        await verifyCognitoUser(token);
        onCloseModal();
        navigate('/');
      } else {
        setErrOTP(errTextOtp);
      }
      setAppLoading(false);
    } catch (error) {
      console.log(error);
      setErrOTP(errTextOtp);
      setAppLoading(false);
    }
  };

  const onHandleNextStep = async () => {
    let awsUser = null;
    let userSignin = null;
    try {
      setAppLoading(true);
      setIsLoading(true);

      const attributes = {
        email: email,
        phone_number: phone
      };

      if (phone) {
        const phone_number = phone.replace(/-/gi, '');
        attributes.phone_number = `+1${phone_number}`;
      }
      const params = {
        username: email,
        password: Math.random().toString(36).slice(-15),
        attributes
      };

      if (signUpUser.shadow_status === 'VERIFY_IDENTITY_REQD') {
        userSignin = await Auth.signIn(email);
        if (userSignin) {
          setUserCognito(userSignin);
        }
      } else {
        awsUser = await Auth.signUp(params);
        userSignin = await Auth.signIn(email);
        console.log('userSignin: ', userSignin);
        if (userSignin) {
          setUserCognito(userSignin);
        }
        if (awsUser && userSignin?.challengeParam?.USERNAME) {
          const response = await register({
            email: email,
            phone_number: attributes.phone_number || '',
            user_id: userSignin?.challengeParam?.USERNAME || ''
          });

          if (response) {
            setSignUpUser({ shadow_status: response?.shadow_status });
          }
        }
      }
      setExpiredOtp(80);
      setSignUpStep(2);
      setAppLoading(false);
      setIsLoading(false);
    } catch (error: any) {
      if (error?.code === 'UsernameExistsException') {
        userSignin = await Auth.signIn(email);
        if (userSignin) {
          setUserCognito(userSignin);
          setSignUpStep(2);
          setExpiredOtp(80);
        }
      } else {
        setErrEmail(unknowErr);
      }
      console.log('err: ', error);
      setAppLoading(false);
      setIsLoading(false);
    }
  };

  const onHanldePreviousSignUpStep = () => {
    setSignUpStep(1);
    setMessageQuicklyResend('');
  };

  const resendOtp = async () => {
    setOtp('');
    try {
      if (seconds === 0) {
        setSeconds(60);
        setExpiredOtp(80);
        setErrOTP('');
        const userCognitoResend = await Auth.signIn(email);
        setMessageQuicklyResend(newCodeSent);
        setUserCognito(userCognitoResend);
      } else {
        setMessageQuicklyResend(`Too quick! Please wait for ${seconds} seconds before clicking ‘Resend’`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clearState = () => {
    setEmail('');
    setPhone('');
    setErrEmail('');
    setErrPhone('');
    setIsLoading(false);
    setIsValidEmail(false);
    setIsValidPhone(false);
    setMessageQuicklyResend('');
    setOtp('');
    setSignUpStep(1);
    setUserCognito({});
    setSeconds(0);
  };

  const onClickSignIn = () => {
    clearState();
    handleClickSignIn();
  };

  const onCloseModal = () => {
    clearState();
    toggle();
  };

  return (
    <ModalCustom isOpen={isOpen} toggle={onCloseModal} onClose={onCloseModal} className="modal-lg modal-signup-account">
      <div>
        <img src={logo} alt="logo" />
      </div>
      {signUpStep === 1 && (
        <div className="modal-container">
          <div className="form-container-stp1">
            <SignUpStep1Header handleClickBtnLogin={onClickSignIn} />
            <div className="pb-2 pt-3">
              <div className="email-input-wrapper">
                <div className="email-input-container">
                  <input value={email} onChange={handleChangeEmail} onBlur={checkValidEmail} />
                  {email && isValidEmail && (
                    <img
                      className="validate-icon"
                      src="https://uthrive-resources.s3.amazonaws.com/extension_assets/field-validation-min.png"
                      alt="icon"
                    />
                  )}
                </div>
                <label>* Your email address (required)</label>
                {errEmail && <div className="err-input">{errEmail}</div>}
              </div>
              <div className="phone-input-wrapper">
                <div className="phone-input-container">
                  <PatternFormat
                    value={phone}
                    onChange={handleChangePhone}
                    onBlur={checkValidPhone}
                    placeholder="***-***-****"
                    format="###-###-####"
                    mask="_"
                  />
                  {phone && isValidPhone && (
                    <img
                      className="validate-icon"
                      src="https://uthrive-resources.s3.amazonaws.com/extension_assets/field-validation-min.png"
                      alt="icon"
                    />
                  )}
                </div>
                <label>* Your phone number (required)</label>
                {errPhone && <div className="err-input">{errPhone}</div>}
              </div>
              <FormGroup check inline>
                <Input
                  className="feedback-checkbox"
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
                <Label className="feedback-checkbox-label" check>
                  I agree to receive text mesages from Uthrive at the phone number provided above. Message and data
                  rates may apply. I can opt out anytime by replying STOP.
                </Label>
              </FormGroup>
            </div>
          </div>
          <div className="create-account-btn-group">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPCHA_SITE_KEY || ''}
              hl="en"
              onChange={onHandleNextStep}
              size="invisible"
              ref={captchaRef}
              className="re-captcha-container"
            />
            <div className="terms-conditions">
              {`By submitting this registration form, I agree with Uthrive’s `}
              <span
                className="btn-text"
                onClick={() => {
                  window.open(`https://www.uthrive.club/terms-and-conditions/`, '_blank');
                }}>
                Terms & Conditions
              </span>
              {` and `}
              <span
                className="btn-text"
                onClick={() => {
                  window.open(`https://www.uthrive.club/privacy-policy/`, '_blank');
                }}>
                Privacy Policy
              </span>
              {`. I understand that I am providing written instructions in accordance with the Fair Credit Reporting Act and other applicable law for Uthrive to request and receive information about me from third parties, including but not limited to a copy of my consumer report and score from consumer reporting agencies, at any time for so long as I have an active Uthrive account. I further authorize Uthrive to retain a copy of my credit information for it’s use and to match with credit products.`}
            </div>

            <div className="pt-2 text-center">
              <CustomButton
                className="btn-confirm"
                disabled={!phone || !email || isLoading || !isValidPhone || !isValidEmail || !isChecked}
                onClick={() => captchaRef.current?.execute()}>
                Accept & Confirm
              </CustomButton>
            </div>
          </div>
        </div>
      )}
      {signUpStep === 2 && (
        <>
          {' '}
          <div className="screen-code">
            <SignUpStep2Header
              handleBack={onHanldePreviousSignUpStep}
              isLoading={isLoading}
              signUpData={{ phone_number_format: phone }}
            />
            <div className="otp-wrapper">
              <div>
                <OtpInput value={otp} handleChage={handleChangeOTP} />
                <div className="otp-resend">
                  Didn’t get the code?{' '}
                  <span className="text-link" onClick={resendOtp}>
                    Resend.
                  </span>
                </div>
                <div className="err-input">{messageQuicklyResend}</div>
                <div className="err-input">{errOTP}</div>
              </div>
            </div>
          </div>
          <CustomButton disabled={otp?.length < 6} onClick={handleConfirmOTP}>
            Continue
          </CustomButton>
        </>
      )}
    </ModalCustom>
  );
};
