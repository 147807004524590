import { LinkedMappingUpdateCardType, ValidatePlaidParamsType } from 'types';
import axiosClient from './axiosClient';
import { endpoints } from './endpoints';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';

export const getPlaidLink = async () => {
  const resp = await axiosClient.get(endpoints.getLinkPlaid);
  return resp.data;
};

export const validatePlaid = async (payload: ValidatePlaidParamsType) => {
  const resp = await axiosClient.post(`${endpoints.validatePlaid}`, payload);
  return resp.data;
};

export const validatePlaidLayer = async (payload: ValidatePlaidParamsType) => {
  const resp = await axiosClient.post(`${endpoints.validatePlaidLayer}`, payload);
  return resp.data;
};

export const getLinkedAccountMappingFailed = async () => {
  const resp = await axiosClient.get(endpoints.linkedAccountMappingFailed);
  return resp.data;
};

export const getMappingCards = async (accountId: string) => {
  const resp = await axiosClient.get(endpoints.linkedAccountCard, { params: { accountId } });
  return resp.data;
};

export const linkedMappingUpdateCard = async (payload: LinkedMappingUpdateCardType) => {
  const resp = await axiosClient.put(`${endpoints.linkedAccountUpdateCard}`, payload);
  return resp.data;
};

export const requestLinkedAccountCalculateReward = async () => {
  const resp = await axiosClient.post(`${endpoints.linkedAccountCalcualteReward}`);
  return resp.data;
};

export const requestUnlinkAccount = async (payload: { accountId: string }) => {
  const resp = await axiosClient.post(`${endpoints.plaidUnlinkAccount}`, payload);
  return resp.data;
};

export const getLinkPlaidRelink = async (accountId: string) => {
  const resp = await axiosClient.get(`${endpoints.linkedAccountCard}/${accountId}`);
  return resp.data;
};

export const getLinkPlaidToken = async (accountId: string) => {
  const resp = await axiosClient.get(`${endpoints.tokenPlaid}/${accountId}`);
  return resp.data;
};

export const getLinkPlaidRenewToken = async (accountId: string, isRenew = true) => {
  const resp = await axiosClient.get(`${endpoints.plaidRelink}/${accountId}`, { params: { isRenew, platform: 'web' } });
  return resp.data;
};

export const postRenewConnectionSuccess = async (
  accountId: string,
  payload: { metadata: PlaidLinkOnSuccessMetadata }
) => {
  const resp = await axiosClient.post(`${endpoints.plaidRelink}/${accountId}/success`, payload);
  return resp.data;
};

export const refreshBank = async (accountId: string) => {
  const resp = await axiosClient.post(`${endpoints.refreshBank}/${accountId}`);
  return resp.data;
};
