export const plaidValidateMessage = [
  {
    type: 0,
    title: 'Duplicate bank login found.',
    content:
      'The bank login you are trying to link is already associated with your Uthrive account.<br/> <br/> Do you want to simply add other card(s) from the same bank login?'
  },
  {
    type: 1,
    title: 'Card account already exists.',
    content:
      'The bank/card you are trying to link already exists with Uthrive. <br/> <br/> Do you want to link another bank/card?'
  },
  {
    type: 2,
    title: 'Looks like you need Family Account.',
    content: `The bank you are trying to link is already associated with your Uthrive account.
  <br/> <br/>
Are you trying to link multiple login from the same bank?
  <br/> <br/>
You need to get Uthrive Family Account available with Uthrive Premium.`,
    hideCloseBtn: true,
    textButton2: 'I don’t want to link this bank'
  },
  {
    type: 3,
    title: 'You haven’t logged in a while, Sync up your cards.',
    content: `As you haven’t visited in a while, your recent transactions need to be synced for calculating your rewards.
  <br/> <br/>
  It takes under 30 seconds and you’re all set!`,
    hideCloseBtn: true,
    textButton: 'Sync my cards',
    textButton2: 'Back'
  },
  {
    type: 4,
    title: 'Link multiple logins from same bank with Family Account.',
    content: `Looks like you trying to link multiple logins from the same bank.
  <br/> <br/>
  You already have cards linked from this bank via an existing login. 
      <br/> <br/>
  To link more cards via another login from the same bank, get Family account with Uthrive Premium.`,
    hideCloseBtn: false,
    textButton: 'Get Family Plan with Uthrive Premium',
    textButton2: ''
  }
];
