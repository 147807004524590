import chevLeft from 'assets/images/chevron-left.svg';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useAppStore, usePlaidStore } from 'store';
import { getLinkedAccountMappingFailed, requestLinkedAccountCalculateReward } from 'services';
import { NotValidateCardCardRecordType } from 'types';
import { CustomButton, LoaderPlaid, ModalPendingValidate, NotValidateCard } from 'components';
import { filter, get } from 'lodash';

export const CardValidation = () => {
  const navigate = useNavigate();

  const { setAppLoading } = useAppStore();
  const { showPlaidLoader, setShowPlaidLoader } = usePlaidStore();

  const [mappingFailedCards, setMappingFailedCards] = useState<NotValidateCardCardRecordType[]>([]);
  const [pendingCard, setPendingCard] = useState<NotValidateCardCardRecordType | null>(null);

  useEffect(() => {
    fetchCards();
  }, []);

  const fetchCards = async () => {
    try {
      setAppLoading(true);
      const resp = await getLinkedAccountMappingFailed();
      setMappingFailedCards(resp.data || []);
      setAppLoading(false);
    } catch (err: any) {
      console.log(err);
      setAppLoading(false);
      navigate(-1);
    }
  };

  const handleRequestCalculateReward = async () => {
    try {
      setShowPlaidLoader(true);
      await requestLinkedAccountCalculateReward();
      setShowPlaidLoader(false);
      navigate(`/linked-account`);
    } catch (err: any) {
      console.log(err);
      setShowPlaidLoader(false);
    }
  };

  const handleCalculateRewards = () => {
    console.log('calculate');
    handleRequestCalculateReward();
  };

  const handleClickCard = (c: NotValidateCardCardRecordType) => {
    if (c.linkStatus === 'PENDING') {
      setPendingCard(c);
    } else if (c.linkStatus === 'NOT_VALIDATED') {
      navigate(`/linked-account/card-selection/${c.accountId}`);
    }
  };

  const handleClickEdit = () => {
    const accountId = pendingCard?.accountId;
    setPendingCard(null);
    navigate(`/linked-account/card-selection/${accountId}`);
  };

  const handleBackBtn = () => {
    navigate(-1);
  };

  const onBackToHome = () => {
    navigate('/');
  };

  const handleClickLinkAccount = () => {
    console.log('link account clicked');
    navigate('/linked-account', { state: { openPlaid: true } });
  };

  const renderData = useMemo(() => {
    return mappingFailedCards?.filter((c) => get(c, 'linkedStatus') !== 'P');
  }, [mappingFailedCards]);

  //count card not validate
  const countCardsNotValidated = useMemo(
    () =>
      filter(renderData, function (o) {
        return o.linkStatus === 'NOT_VALIDATED';
      }).length || 0,
    [renderData]
  );
  //count card validated
  const countCardsValidated = useMemo(
    () =>
      filter(renderData, function (o) {
        return o.linkStatus === 'VALIDATED';
      }).length || 0,
    [renderData]
  );
  //count card pending
  const countCardsPending = useMemo(
    () =>
      filter(renderData, function (o) {
        return o.linkStatus === 'PENDING';
      }).length || 0,
    [renderData]
  );

  return (
    <>
      <div className="card-validation">
        <button className="linked-account-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <div className="linked-account-header mb-4">
          <h2 className="linked-account-header-title text-center">Card Validation</h2>
        </div>
        <div className="card-validation-title">Card validation</div>
        {countCardsPending === renderData.length ? (
          <div className="card-validation-subtitle">
            {`\nBased on your input, the following card is being validated by Uthrive to link successfully. There is no action needed from you.`}
          </div>
        ) : !countCardsNotValidated ? (
          <div className="card-validation-subtitle">
            {`\nValidation successful\n\nYour cards have been successfully validated. Click below to calculate rewards. `}
          </div>
        ) : (
          <div className="card-validation-subtitle">
            {`\nWe received incomplete information for below accounts from the bank, please validate the card names to link successfully.`}
          </div>
        )}
        <div className="mt-5">
          {renderData?.map((c, idx) => {
            return <NotValidateCard key={idx} data={c} isValidatePage handleClick={() => handleClickCard(c)} />;
          })}
        </div>
        <div className="text-center mt-5">
          {!!renderData?.length &&
            (renderData.length === countCardsPending && renderData[0].linkStatus === 'PENDING' ? (
              <CustomButton onClick={onBackToHome}>Back to home</CustomButton>
            ) : (
              <CustomButton disabled={!countCardsValidated} onClick={handleCalculateRewards}>
                Calculate rewards
              </CustomButton>
            ))}
        </div>
      </div>
      <ModalPendingValidate
        isOpen={!!pendingCard}
        onClickBtn={handleClickEdit}
        onClose={() => setPendingCard(null)}
        cardName={`${pendingCard?.institutionName} ${pendingCard?.accountSubtype} ${pendingCard?.accountMask ? '- ' + pendingCard?.accountMask : ''}`}
      />
      <LoaderPlaid isLoading={showPlaidLoader} onOpenPlaid={handleClickLinkAccount} />
    </>
  );
};
