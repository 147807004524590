import { LinkedAccountStoreType } from 'types';
import { create } from 'zustand';

const initState = {
  linkedData: {
    manualAccounts: [],
    notValidated: [],
    update2Links: [],
    upToDate: []
  }
};

export const useLinkedAccountStore = create<LinkedAccountStoreType>((set) => ({
  linkedData: { ...initState.linkedData },
  setLinkedData: (value) =>
    set(() => {
      return { linkedData: value };
    }),
  reset: () => set({ ...initState })
}));
