import * as React from 'react';
import Countdown from 'react-countdown';
import { LoaderThreeDots } from 'components/LoaderThreeDots';
import { CustomButton } from 'components/Button';
import './style.scss';

const typeLog: string[] = [];

type props = {
  isLoading?: boolean;
  onOpenPlaid?: () => void;
};

export const LoaderPlaid = ({ isLoading = false, onOpenPlaid }: props) => {
  if (!isLoading) {
    return null;
  }
  return (
    <div id="pre-loader" className="loader loader-wrapper">
      <div className="text-header">Please do not refresh or click the back button</div>
      <div className="group-loader">
        <div>
          <LoaderThreeDots />
          <Countdown
            precision={100}
            date={Date.now() + 45000}
            renderer={({ seconds }) => {
              if (seconds > 34) {
                if (typeLog.indexOf('analyzing') === -1) {
                  typeLog.push('analyzing');
                }
                return <div className="mt-3 creating-plaid">Analyzing your spending habits…</div>;
              }

              if (seconds > 23) {
                if (typeLog.indexOf('rewards') === -1) {
                  typeLog.push('rewards');
                }

                return <div className="mt-3 creating-plaid ">Updating your rewards information...</div>;
              }

              if (seconds > 12) {
                if (typeLog.indexOf('personalized') === -1) {
                  typeLog.push('personalized');
                }

                return <div className="mt-3 creating-plaid">Creating personalized advice for you...</div>;
              }

              if (typeLog.indexOf('almost') === -1) {
                typeLog.push('almost');
              }
              return <div className="mt-3 creating-plaid">Almost there...</div>;
            }}
          />
        </div>
        <div className="link-more-account-btn">
          <CustomButton onClick={onOpenPlaid ? onOpenPlaid : undefined}>Link More Accounts</CustomButton>
        </div>
      </div>
    </div>
  );
};
