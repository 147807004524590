import nextIcon from 'assets/images/next.png';
import './style.scss';
import { ModalCustom } from 'components/ModalCustom';
import { CustomButton } from 'components/Button';
import { useRef, useState } from 'react';
import { PopoverDownloadApp } from 'components/PopoverDownloadApp';

type props = {
  name: string;
  reward: string;
  logo: string;
  backgroundLogo: string;
  handleClickItem: () => void;
  isPremium?: boolean;
  isBankOffer?: boolean;
};

export const OfferItem = ({
  name,
  reward,
  logo,
  backgroundLogo,
  handleClickItem,
  isPremium = false,
  isBankOffer = false
}: props) => {
  const detailRef = useRef<HTMLDivElement>(null);

  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [showHowItWork, setShowHowItWork] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  return (
    <>
      <div className="offer-item" onClick={handleClickItem}>
        <img src={backgroundLogo} alt="logo" className="offer-item-background" />
        <div className="offer-item-info-container">
          <div className="offer-item-name">
            <div className="name">{name}</div>
            <div className="reward">{isBankOffer ? `${reward}` : `Up to ${reward}`}</div>
          </div>
          <img src={nextIcon} alt="arrow" className="icon-next" />
          {logo && <img src={logo} alt="logo" className="offer-item-logo" />}
        </div>
      </div>
      <ModalCustom
        className="modal-offer-item"
        isOpen={openInfoModal}
        toggle={() => setOpenInfoModal(!openInfoModal)}
        onClose={() => setOpenInfoModal(!openInfoModal)}>
        <div ref={detailRef} className="content">
          <h3 className="title">
            Unlock this offer with <br />
            <span className="highlight">Uthrive Premium.</span>
          </h3>
          <div className="offer-item">
            <img src={backgroundLogo} alt="logo" className="offer-item-background" />
            <div className="offer-item-info-container">
              <div className="offer-item-name">
                <div className="name">{name}</div>
                <div className="reward">Up to {reward}</div>
              </div>
              <img src={logo} alt="logo" className="offer-item-logo" />
            </div>
            <div className="offer-item-howItWorks">
              <div className="offer-item-howItWorks-rate-footer">
                {' '}
                <div className="get-offer" onClick={() => setShowHowItWork(!showHowItWork)}>
                  How it works?
                </div>{' '}
                {showHowItWork && (
                  <div className="btn-close-details" onClick={() => setShowHowItWork(!showHowItWork)}>
                    X
                  </div>
                )}
              </div>
              {showHowItWork && (
                <div className="how-it-work-text">
                  Simply tap on &apos;Get Offer&apos; to visit merchant site and make purchases. You will get cash back
                  from Uthrive on qualifying purchase amount, which you can redeem into your checking or savings account
                  via PayPal. You will earn this, in addition to rewards or cashback offered by your credit card.
                </div>
              )}
            </div>
          </div>
          <div className="desc">
            <p>
              Unlock over <span className="highlight">$400+</span> in automatic maximum savings and rewards at 100,000+
              stores with Uthrive Premium:
            </p>
            <ol>
              <li>Auto-activate savings offers on your credit or debit cards</li>
              <li>Auto-activate digital coupons at popular grocery and pharmacy stores</li>
              <li>Know your best card to use for maximum rewards on every purchases</li>
            </ol>
          </div>
        </div>

        <CustomButton id="popover-qr-premium" onClick={() => setShowPopover(true)}>
          Get Uthrive Premium
        </CustomButton>
        <PopoverDownloadApp
          target="popover-qr-premium"
          isOpen={showPopover}
          handleDone={() => setShowPopover(false)}
          title="Download Uthrive App"
          placement="top"
        />
      </ModalCustom>
    </>
  );
};
