import React from 'react';
import nextIcon from 'assets/images/next.png';
import './style.scss';
import { SpentRecordGroupType } from 'types';
import { fixMoney } from 'utils';
import moment from 'moment';

type props = {
  data: SpentRecordGroupType;
  handleClickItem?: () => void;
};

export const SpentRewardGroupBoxItem = ({ data, handleClickItem }: props) => {
  return (
    <div className="missed-reward-group-item" onClick={handleClickItem ? handleClickItem : undefined}>
      <img
        className="missed-reward-group-category"
        src={data?.merchantImageUrl || data?.categoryImageUrl}
        alt="category"
      />
      <div className="missed-reward-group-infoBox">
        <h3 className="missed-reward-group-title">{data?.mappedMerchant || data?.category}</h3>
        <div className="missed-reward-group-subtitle">Last paid: {moment(data?.lastPaidDate).format('MM/DD')}</div>
      </div>
      <div className="missed-reward-group-nextIcon">
        <div className="missed-reward-group--rewards">${fixMoney(data?.lastPaidAmount)}</div>
        <img src={nextIcon} alt="next" />
      </div>
    </div>
  );
};
