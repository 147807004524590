import { AppStoreType } from 'types';
import { create } from 'zustand';

const initState = {
  appLoading: false,
  loadingMessage: '',
  offersData: {},
  category: null,
  categories: [],
  categoriesMySavings: [],
  filteredOffersData: { filterBy: '', data: [] },
  subscriptions: [],
  plans: [],
  stripeConfig: null,
  userSubscriptionConfig: null,
  intentConfig: null,
  activeSubscription: null,
  recommendedCardHeadline: '',
  hasMoreAll: true,
  hasMoreFilterd: true
};

export const useAppStore = create<AppStoreType>((set) => ({
  appLoading: false,
  loadingMessage: '',
  offersData: {},
  category: null,
  categories: [],
  categoriesMySavings: [],
  filteredOffersData: { filterBy: '', data: [] },
  subscriptions: [],
  plans: [],
  stripeConfig: null,
  userSubscriptionConfig: null,
  intentConfig: null,
  activeSubscription: null,
  recommendedCardHeadline: '',
  hasMoreAll: true,
  hasMoreFilterd: true,
  rewardsSummary: { earnedTotal: 0, missedTotal: 0 },
  setHasMoreAll: (value) =>
    set(() => {
      return { hasMoreAll: value };
    }),
  setHasMoreFilterd: (value) =>
    set(() => {
      return { hasMoreFilterd: value };
    }),
  setAppLoading: (value) =>
    set(() => {
      if (!value) {
        return { appLoading: value, loadingMessage: '' };
      }
      return { appLoading: value };
    }),
  setCategories: (value) => set({ categories: value }),
  setOffersData: (value) =>
    set(() => {
      return { offersData: value };
    }),
  setFilteredOffersData: (value, filterBy) =>
    set(() => {
      const temp = { filterBy: filterBy, data: value };
      return { filteredOffersData: temp };
    }),
  setSubscriptions: (value) =>
    set(() => {
      return { subscriptions: value };
    }),
  setActiveSubscription: (value) =>
    set(() => {
      return { activeSubscription: value };
    }),
  setPlans: (value) =>
    set(() => {
      return { plans: value };
    }),
  setStripeConfig: (value) =>
    set(() => {
      return { stripeConfig: value };
    }),
  setUserSubscriptionConfig: (value) =>
    set(() => {
      return { userSubscriptionConfig: value };
    }),
  setIntentConfig: (value) =>
    set(() => {
      return { intentConfig: value };
    }),
  setRecommendedCardHeadline: (value) =>
    set(() => {
      return { recommendedCardHeadline: value };
    }),
  setCategory: (value) =>
    set(() => {
      return { category: value };
    }),
  setCategoriesMySavings: (value) =>
    set(() => {
      return { categoriesMySavings: value };
    }),
  reset: () => set({ ...initState }),
  setDataOffers: (category, data) =>
    set((state) => ({
      offersData: { ...state.offersData, [category]: data }
    })),
  setLoadingMessage: (value) =>
    set(() => {
      return { loadingMessage: value };
    }),
  setRewardsSummary: (value) =>
    set(() => {
      return { rewardsSummary: value };
    })
}));
