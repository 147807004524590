import React from 'react';
import nextIcon from 'assets/images/next.png';
import './style.scss';
import { SpentRecordType } from 'types';
import { formatMoney } from 'utils';

type props = {
  data: SpentRecordType;
  handleClickItem?: () => void;
};

export const SpentRewardBoxItem = ({ data, handleClickItem }: props) => {
  return (
    <div className="spent-category-reward-item" onClick={handleClickItem ? handleClickItem : undefined}>
      <h3 className="spent-category-reward-title">{data?.category}</h3>
      <div className="spent-category-reward-infoBox">
        <div className="spent-category-reward-image">
          <img src={data?.categoryImageUrl} alt="category" />
        </div>
        <div className="spent-category-reward-sm">
          <div className="spent-category-reward-sm-item">
            <span className="spent-category-reward-sm-title">Spent</span>
            <p className="spent-category-reward-sm-price">${formatMoney(data?.totalSpending)}</p>
          </div>
        </div>
      </div>
      <div className="spent-category-reward-nextIcon">
        <img src={nextIcon} alt="next"></img>
      </div>
    </div>
  );
};
