import './style.scss';
import iconClose from 'assets/images/close-icon-white.png';

type props = {
  title: string;
  onClickYes?: () => void;
  onClickNo?: () => void;
  id?: string;
  cancelText?: string;
  className?: string;
  showBtns?: boolean;
  supportClose?: boolean;
};

export const PopupNotify = ({
  title,
  onClickYes,
  onClickNo,
  cancelText = 'No',
  id = 'btn-id',
  className = '',
  showBtns = true,
  supportClose = false
}: props) => {
  return (
    <>
      <div className={`notify-common ${className}`}>
        {supportClose && (
          <div className="notify-common-close-icon" onClick={onClickNo}>
            <img src={iconClose} alt="close" />
          </div>
        )}
        <div className={`notify-common-text ${supportClose ? 'mt-3' : ''}`}>{title}</div>
        {showBtns && (
          <div className="notify-common-prompt">
            <button className="notify-common-button" onClick={onClickYes} id={id}>
              Yes, take me there.
            </button>
            <button className="notify-common-button" onClick={onClickNo}>
              {cancelText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};
