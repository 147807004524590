import { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';
import { useAuthStore, usePlaidStore } from 'store';

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_IO;
const TRANSPORTS = ['websocket'];

export const useListenerRewards = (callbackSuccess: (data: any) => void) => {
  const { token } = useAuthStore();
  const { setProgress, setShowPlaidLoader, setShowProgress } = usePlaidStore();
  const [rewards, setRewards] = useState<any>(null);
  const socketInstanceRef = useRef<any>(null);

  const setupSocketListeners = (socket: any, callbackConnected?: () => void) => {
    socket.emit('listenUser', '');

    socket.on('connect', () => {
      console.log('[[socket rewards connected]]');
      callbackConnected?.();
    });

    socket.on('calculatedReward', (data: any) => {
      console.log('[[calculatedReward]]', data);
      if (data.data && data?.message !== 'FAILED') {
        setRewards(data?.data);
        callbackSuccess(data);
      }
    });

    socket.on('listenedUser', (data: any) => {
      console.log('[[listenedUser]]', data);
    });

    socket.on('disconnect', () => {
      console.log('[[socket disconnected]]');
      socket.removeAllListeners();
      socket.disconnect();
      setRewards(null);
      triggerConnect();
    });

    socket.on('connect_error', (err: any) => {
      console.log('connect_error: ', err);
      setShowProgress(false);
      setShowPlaidLoader(false);
      setProgress(0);
      setRewards(null);
    });
  };

  const triggerConnect = (callbackConnected?: () => void) => {
    if (socketInstanceRef.current) {
      socketInstanceRef.current.disconnect();
    }
    if (!token) return;

    const socketInstance = io(SOCKET_SERVER_URL, {
      transports: TRANSPORTS,
      path: '/socket.io',
      query: { authorization: `Bearer ${token}` },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 0,
      reconnectionDelayMax: 3000
    });

    socketInstanceRef.current = socketInstance;
    setupSocketListeners(socketInstance, callbackConnected);
  };

  useEffect(() => {
    return () => {
      socketInstanceRef.current?.disconnect();
    };
  }, [token]);

  return { rewards, triggerConnect };
};
