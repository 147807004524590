import { Auth } from 'aws-amplify';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useAuthStore } from 'store';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  timeout: 800000
});

axiosClient.interceptors.request.use(
  async (config) => {
    // Add configurations here

    let token = useAuthStore.getState().token || '';
    if (!token) return config;
    const currentSession = await Auth.currentSession()
      .then((session) => {
        return session;
      })
      .catch((error) => console.log(error));

    if (currentSession) {
      const idTokenExpire = currentSession.getIdToken().getExpiration();
      const currentTimeSeconds = Math.round(+new Date() / 1000);
      if (idTokenExpire < currentTimeSeconds) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const refreshToken = currentSession.getRefreshToken();
        await cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
          if (session) {
            const accessToken = session?.accessToken || null;
            token = accessToken?.jwtToken || null;
          }
          if (err) {
            return null;
          }
        });
      } else {
        const accessToken = currentSession?.getAccessToken() || null;
        token = accessToken?.getJwtToken();
      }
    }
    if (token) {
      useAuthStore.getState().setToken(token);
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Response interceptor
axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    // Modify response data if needed
    return response;
  },
  (error: AxiosError) => {
    // Handle response errors
    if (error.response) {
      // The request was made and the server responded with a status code
      const statusCode = error.response.status;

      if (statusCode === 404) {
        console.error('Resource not found');
      } else if (statusCode === 401) {
        console.error('Unauthorized');
        useAuthStore.getState().doLogout();

        // Handle authentication issues, e.g., redirect to login screen
      } else {
        console.error(`Server responded with status code: ${statusCode}`);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received');
    } else {
      // Something happened in setting up the request
      console.error('Error setting up the request', error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
