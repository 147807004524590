import { RatingEmailParamsType, TrackingParamsType } from 'types';
import axiosClient from './axiosClient';
import { endpoints } from './endpoints';

export const sendRatingEmail = async (params: RatingEmailParamsType) => {
  const resp = await axiosClient.post(endpoints.trackingFeedback, params);
  return resp.data;
};

export const updateRatingEmail = async ({ id, params }: { id: string; params: RatingEmailParamsType }) => {
  const resp = await axiosClient.put(`${endpoints.trackingFeedback}/${id}`, params);
  return resp.data;
};

export const getShadowId = async () => {
  const resp = await axiosClient.get(endpoints.shadowId);
  return resp.data;
};

export const clickTracking = async (params: TrackingParamsType) => {
  const resp = await axiosClient.post(endpoints.clickTracking, params);
  return resp.data;
};
