import { ModalCustom } from 'components/ModalCustom';
import checkIcon from 'assets/images/green-check-icon.svg';
import { useState } from 'react';

type props = {
  isOpen: boolean;
  onClose: () => void;
  merchantName: string;
};

export const ModalWFOfferTerms = ({ isOpen, onClose, merchantName = '' }: props) => {
  const [isMore, setIsMore] = useState(false);

  return (
    <ModalCustom
      isOpen={isOpen}
      className="custom-modal-offer-tc"
      toggle={() => {
        onClose();
      }}
      onClose={() => {
        onClose();
      }}
      isSupportClose>
      <div className="offerDescription-container">
        <div className="offerDescription--header">
          <div>
            <span className="active-check">
              <img src={checkIcon} alt="chk icon" />
              Offer Activated
            </span>
          </div>
          <div className="offerDescription--merchantName">Offer Details & Terms</div>
        </div>
        <div className={isMore ? 'read-more' : 'no-read-more'}>
          <p>Tap the button to activate rewards (such as cashback or coupons) during your online shopping session.</p>
          <p>
            <b>Merchant:</b> {merchantName}
          </p>
          <p>
            <b>Eligible Transaction:</b> A purchase from {merchantName} made by utilizing the {'Uthrive'} browser
            extension and immediately completing the transaction.
          </p>
          <p>
            <b>Coupons:</b> If coupon offers are presented to you by the {'Uthrive'} browser extension and you tap to
            activate coupons, the extension will automatically test coupon codes to determine which, if any, saves the
            most money. Coupon codes may not apply to your purchase, depending on which items are in your shopping cart,
            timing, location, or other factors.
          </p>
          <p>
            <b>Reward amounts:</b> Reward amount is calculated by the merchant, based upon the applicable rates and
            exclusions, which may change from time to time. Amounts may be adjusted or canceled due to returned items,
            suspected fraud, or other factors described within the terms and conditions.
          </p>
          <p>
            <b>Payment timing:</b> On average, reward payouts occur 60-120 days after your completed transaction, but
            some rewards may take 180 days or longer. We provide a reward to you, in monthly batches, after we receive
            payment for that specific transaction from the Merchant. Merchants usually have holdback periods during
            which they hold a pending reward until the expiration of a return policy or until a service is consumed.
            Reward payouts may be subject to minimum payout thresholds and dormant account fees.
          </p>
          <p>
            See a summary of offer{' '}
            <a href="https://www.uthrive.club/terms-and-conditions" target="_blank" rel="noreferrer">
              terms and conditions
            </a>
            .
          </p>
        </div>
        {!isMore && (
          <div
            className="offerDescription--read-more"
            onClick={() => {
              setIsMore(!isMore);
            }}>
            Read more
          </div>
        )}
      </div>
    </ModalCustom>
  );
};
