import React from 'react';
import './style.scss';
import { NotValidateCardCardRecordType } from 'types';
import { useNavigate } from 'react-router-dom';

type props = {
  data: NotValidateCardCardRecordType;
  handleValidate?: () => void | undefined;
};

export const EarnedRewardsNotValidate = ({ data }: props) => {
  const navigate = useNavigate();

  const handleClickRenew = () => {
    navigate(`/linked-account/card-selection/${data?.accountId}`);
  };

  return (
    <div className="earned-reward-notvalidate-item">
      <div>
        <h3 className="earned-reward-notvalidate-title">{`${data?.institutionName} ${data?.accountSubtype} ${data?.accountMask ? ` - ${data?.accountMask}` : ''}`}</h3>
      </div>
      <div className="earned-reward-notvalidate-infoBox">
        <div className="earned-reward-notvalidate-image me-2">
          <img src={data?.cardImage} alt="category" />
        </div>
        <div>This card info was not received from your bank. Validate to view rewards.</div>
        <div className="earned-reward-notvalidate-link" onClick={handleClickRenew}>
          Validate
        </div>
      </div>
    </div>
  );
};
