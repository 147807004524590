import React, { useState, useEffect, ReactNode } from 'react';
import './style.scss';

type props = {
  children: ReactNode;
  isVisible: boolean;
};

export const FadeOutComponent = ({ children, isVisible }: props) => {
  const [shouldRender, setShouldRender] = useState(isVisible);

  useEffect(() => {
    if (!isVisible) {
      const timeoutId = setTimeout(() => setShouldRender(false), 500);
      return () => clearTimeout(timeoutId);
    } else {
      //   setShouldRender(true);
    }
  }, [isVisible]);

  return shouldRender ? <div className={`fade-out ${!isVisible ? 'fade-out-leave' : ''}`}>{children}</div> : null;
};
