import React from 'react';
import { Progress } from 'reactstrap';
import './style.scss';

type props = {
  value: number;
};

export const LinkPlaidProgressBar = ({ value = 0 }: props) => {
  return (
    <div className="progress-bar-wrapper">
      <Progress className="bar-wrapper" barClassName="progress-wrapper" value={value} />
      <div className="progress-bar-text">
        We are updating your transactions for the card you just linked. This is one time only.
      </div>
    </div>
  );
};
