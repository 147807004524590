import React, { useState, useEffect } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown
} from 'reactstrap';
import './style.scss';
import logo from 'assets/images/logo.svg';
import {
  ModalAuthenMethods,
  ModalConsentCapture,
  ModalExistAccount,
  ModalLogin,
  ModalNotFoundAccount,
  ModalSignUp,
  PopoverDownloadApp
} from 'components';
import { useAuthStore, useAppStore } from 'store';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth, Hub } from 'aws-amplify';
import { checkingSoicalAccNotFound, consentCaptureSocial, registerSocial } from 'services';
import userIcon from 'assets/images/user-icon.png';
import menuIcon from 'assets/images/menu-icon.png';
import { toast } from 'react-toastify';
import { logoutMessage } from 'const';
import { sleepFunc } from 'utils';
import { get } from 'lodash';

export function AppNavbar() {
  const location = useLocation();

  const { token, doLogout, browserLocation, resetBrowserLocation, user } = useAuthStore();

  const navigate = useNavigate();

  const { setToken } = useAuthStore();
  const { setAppLoading, setLoadingMessage } = useAppStore();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModalLogin, setIsOpenModalLogin] = useState(false);
  const [isOpenModalSignUp, setIsOpenModalSignUp] = useState(false);
  const [isOpenModalSignUpMethods, setIsOpenModalSignUpMethods] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [isOpenConsentCapture, setIsOpenConsentCapture] = useState(false);
  const [tempToken, setTempToken] = useState('');
  const [firstName, setFirstName] = useState('');
  const [isOpenModalExistAcc, setIsOpenModalExistAcc] = useState(false);
  const [isOpenModalNotFoundAcc, setIsOpenModalNotFoundAcc] = useState(false);
  const [isFromLandingSite, setIsFromLandingSite] = useState(false);

  const isEmailAuthen =
    !get(user, 'linkedGoogleLoginId') && !get(user, 'linkedFbLoginId') && !get(user, 'linkedAppleLoginId');

  const isGoogleAuthen = get(user, 'linkedGoogleLoginId');

  useEffect(() => {
    if (!location?.search) {
      return;
    }

    const queryParams = new URLSearchParams(location?.search);
    const fromLanding = queryParams.get('fromLandingSite');
    const isLogin = queryParams.get('signin');
    const isSignup = queryParams.get('signup');
    const redirectUrl = queryParams.get('redirectUrl');
    const isAirbridgeLink = queryParams.get('airbridge_referrer');
    const isCallbackExtension = queryParams.get('extension');

    if (isCallbackExtension === 'true') {
      const token = localStorage.getItem('token');
      if (token) {
        setTimeout(() => {
          window?.postMessage({ type: 'LOGGED_USER' }, '*');
        }, 3000);
      } else {
        localStorage.setItem('extension', 'true');
      }
    }

    ///Fetch is google login cond///
    const isGoogleLogin = queryParams.get('googleLogin');

    if (token && isGoogleAuthen && isGoogleLogin === 'true') {
      window.location.replace(`${redirectUrl}?info=${token}`);
    }

    if (token && (fromLanding === 'true' || isLogin === 'true' || isSignup === 'true')) {
      navigate('/', { replace: true });
      return;
    }

    if (isAirbridgeLink && !token) {
      setIsOpenModalSignUpMethods(true);
      setIsSignIn(true);
      return;
    }

    if (fromLanding === 'true') {
      setIsFromLandingSite(true);
    }

    if (isLogin === 'true') {
      setIsOpenModalSignUpMethods(true);
      setIsSignIn(true);
    } else if (isSignup === 'true') {
      setIsOpenModalSignUpMethods(true);
      setIsSignIn(false);
    }

    if (isGoogleLogin === 'true') {
      if (redirectUrl) {
        localStorage.setItem('previous_url', redirectUrl);
      }
      setIsSignIn(true);
      handleClickGoogle();
    }
  }, [location, token]);

  useEffect(() => {
    if (localStorage.getItem('notFoundAcc') === 'true') {
      localStorage.removeItem('notFoundAcc');
      setIsOpenModalSignUpMethods(false);
      setIsOpenModalNotFoundAcc(true);
    }
    if (localStorage.getItem('reloaded') === 'true') {
      localStorage.removeItem('reloaded');

      window.location.replace('https://www.uthrive.club/');
    }
    if (localStorage.getItem('ExistAcc') === 'true') {
      localStorage.removeItem('ExistAcc');
      setIsOpenModalSignUpMethods(false);
      setIsOpenModalExistAcc(true);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      let token = '';
      let email = '';
      let first_name = '';
      let last_name = '';
      let checkSocial = false;
      switch (event) {
        case 'signIn':
          token = data?.signInUserSession?.accessToken?.jwtToken;
          email = data?.signInUserSession?.idToken?.payload?.email;
          first_name = data?.signInUserSession?.idToken?.payload?.given_name;
          last_name = data?.signInUserSession?.idToken?.payload?.family_name;

          checkSocial = !!data?.signInUserSession?.idToken?.payload?.identities;
          setIsOpenModalSignUpMethods(false);
          if (checkSocial) {
            console.log('name 1: ', { first_name, last_name });
            handleSignIn(token, email, first_name, last_name);
          }

          return;
        case 'signOut':
          // doLogout();
          // setToken('');

          break;
        case 'customOAuthState':
          console.log('customOAuthState ', data);
      }
    });

    return unsubscribe;
  }, []);

  const handleSignIn = async (token: string, email: string, first_name: string, last_name: string) => {
    if (!token || !email) return;
    const prevUrl = localStorage.getItem('previous_url');
    try {
      setAppLoading(true);
      const isSocialSignin = JSON.parse(localStorage.getItem('signin_social') || '') || false;
      if (prevUrl) {
        try {
          await checkingSoicalAccNotFound(token);
          setToken(token);
          localStorage.removeItem('previous_url');
          window.location.replace(`${prevUrl}?info=${token}`);
        } catch (err: any) {
          console.log('err social: ', err);
          const resp = await registerSocial(
            {
              email: email,
              firstName: first_name,
              lastName: last_name
            },
            token,
            'web-indirect'
          );
          console.log('Response: ', resp?.message);
          if (resp?.message) {
            await handleLogout();
            setAppLoading(false);
            return;
          }
          setTempToken(token);
          setFirstName(first_name);
          setIsOpenConsentCapture(true);
          setAppLoading(false);
        }
        return;
      } else {
        if (isSocialSignin) {
          try {
            await checkingSoicalAccNotFound(token);
            setToken(token);

            if (browserLocation && browserLocation?.pathname !== '') {
              navigate(`${browserLocation.pathname}${browserLocation?.search}`);
              resetBrowserLocation();
            } else {
              navigate('/');
            }
            setAppLoading(false);
          } catch (err: any) {
            console.log('err social: ', err);
            handleNoFoundAccount();
            setAppLoading(false);
          }
          return;
        }
        const resp = await registerSocial(
          {
            email: email,
            firstName: first_name,
            lastName: last_name
          },
          token
        );
        if (resp?.message) {
          await handleLogout();
          setAppLoading(false);
          return;
        }
        setTempToken(token);
        setFirstName(first_name);
        setIsOpenConsentCapture(true);
        setAppLoading(false);
      }
    } catch (err: any) {
      console.log(err);
      setAppLoading(false);
      toast.error('Something went wrong');
    }
  };

  const handleClickFacebook = () => {
    localStorage.setItem('signin_social', JSON.stringify(isSignIn));
    localStorage.setItem('social_name', 'Facebook');
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
  };

  const handleClickGoogle = () => {
    localStorage.setItem('signin_social', JSON.stringify(isSignIn));
    localStorage.setItem('social_name', 'Google');
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  const toggle = () => setIsOpen(!isOpen);
  const toggleModalLogin = () => setIsOpenModalLogin(!isOpenModalLogin);
  const toggleModalSignUp = () => setIsOpenModalSignUp(!isOpenModalSignUp);
  const toggleModalSignUpMethods = () => setIsOpenModalSignUpMethods(!isOpenModalSignUpMethods);

  const handleCloseModalAuthenMethods = () => {
    setIsOpenModalSignUpMethods(false);
    if (isFromLandingSite) {
      window.location.replace('https://www.uthrive.club/');
    }
  };

  const handleClickSignUp = () => {
    setIsOpenModalLogin(false);
    setIsOpenModalSignUp(true);
  };

  const handleClickSignIn = () => {
    setIsOpenModalSignUp(false);
    setIsOpenModalSignUpMethods(true);
    setIsSignIn(true);
  };

  const handleClickSelectEmailMethod = () => {
    if (isSignIn) {
      setIsOpenModalLogin(true);
    } else setIsOpenModalSignUp(true);
    setIsOpenModalSignUpMethods(false);
  };

  const handleLogout = async () => {
    try {
      setAppLoading(true);
      localStorage.setItem('ExistAcc', 'true');
      doLogout();
      await Auth.signOut();
      setAppLoading(false);
      setIsOpenModalExistAcc(true);
    } catch (err) {
      setAppLoading(false);
      console.log('err: ', err);
    }
  };

  const handleNoFoundAccount = async () => {
    try {
      setAppLoading(true);
      localStorage.setItem('notFoundAcc', 'true');
      doLogout();
      await Auth.signOut();
      setAppLoading(false);
      setIsOpenModalNotFoundAcc(true);
    } catch (err) {
      setAppLoading(false);
      console.log('err: ', err);
    }
  };

  const handleClickLogout = async () => {
    try {
      setAppLoading(true);
      setTimeout(() => {
        resetBrowserLocation();
      }, 2000);
      setLoadingMessage(logoutMessage);
      if (!isEmailAuthen) {
        localStorage.setItem('reloaded', 'true');
      }
      await sleepFunc(1000);
      doLogout();
      await Auth.signOut();
      setAppLoading(false);
      if (isEmailAuthen) {
        window.location.replace('https://www.uthrive.club/');
      }
    } catch (err) {
      setAppLoading(false);
      console.log('err: ', err);
    }
  };

  const handleClickPolicy = () => {
    navigate('/privacy-policy', { replace: true });
  };

  const handleClickTerm = () => {
    navigate('/terms-and-conditions', { replace: true });
  };

  const handleClickProfile = () => {
    navigate('/profile', { replace: true });
  };

  const handleClickContact = () => {
    navigate('/contact-us', { replace: true });
  };

  const handleConsentCapture = async () => {
    try {
      const prevUrl = localStorage.getItem('previous_url');
      if (prevUrl) {
        localStorage.removeItem('previous_url');
        window.location.replace(`${prevUrl}?info=${tempToken}`);
        return;
      }
      setAppLoading(true);
      await consentCaptureSocial(tempToken);
      setToken(tempToken);

      setIsOpenConsentCapture(false);
      navigate('/', { replace: true });
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const isExtensionPage = location?.pathname.includes('/uninstall-extension');
  const handleClickLinkedAccount = () => {
    navigate('/linked-account');
  };

  return (
    <div className={isExtensionPage ? 'd-none' : ''}>
      <Navbar className="custom-navbar" fixed="top" expand="lg">
        <NavbarBrand href="/">
          <img
            alt="logo"
            src={logo}
            style={{
              height: 22,
              width: 116
            }}
          />
        </NavbarBrand>
        <NavbarToggler className={isOpen ? 'expanded' : ''} onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto custom-nav" navbar>
            {token ? (
              <>
                <NavItem>
                  <NavLink className="custom-navlink" to="/">
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="custom-navlink" to="/offers">
                    Offers
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="custom-navlink" to="/cards">
                    Cards
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="custom-navlink" to="/premium">
                    Premium
                  </NavLink>
                </NavItem>
              </>
            ) : null}
          </Nav>
          <UncontrolledDropdown>
            <DropdownToggle className="custom-button-dropdown-toggle" id="profile-btn">
              <img src={menuIcon} alt="icon" className="btn-user-container--menu" />
              <div className="btn-user-container--user">
                <img src={userIcon} alt="user" />
              </div>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={() => setIsOpenPopover(!isOpenPopover)}>Get Uthrive App</DropdownItem>
              {token && <DropdownItem onClick={handleClickContact}>Contact Us</DropdownItem>}
              {!token && (
                <>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => {
                      setIsSignIn(false);
                      toggleModalSignUpMethods();
                    }}>
                    Sign up
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setIsSignIn(true);
                      toggleModalSignUpMethods();
                    }}>
                    Log in
                  </DropdownItem>
                </>
              )}
              {token && (
                <>
                  <DropdownItem divider />
                  <DropdownItem onClick={handleClickProfile}>Profile</DropdownItem>
                  <DropdownItem onClick={handleClickLinkedAccount}>Linked Accounts</DropdownItem>
                  <DropdownItem onClick={handleClickPolicy}>Privacy Notice</DropdownItem>
                  <DropdownItem onClick={handleClickTerm}>Terms & Conditions</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={handleClickLogout}>Sign out</DropdownItem>
                </>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Collapse>
      </Navbar>
      <ModalLogin isOpen={isOpenModalLogin} toggle={toggleModalLogin} handleClickSignup={handleClickSignUp} />
      <ModalSignUp isOpen={isOpenModalSignUp} toggle={toggleModalSignUp} handleClickSignIn={handleClickSignIn} />
      <ModalAuthenMethods
        isOpen={isOpenModalSignUpMethods && !isOpenModalExistAcc}
        toggle={handleCloseModalAuthenMethods}
        handleClickEmail={handleClickSelectEmailMethod}
        handleClickLogin={() => {
          setIsSignIn(true);
        }}
        handleClickSignup={() => {
          setIsSignIn(false);
        }}
        isSignin={isSignIn}
        handleClickFacebook={handleClickFacebook}
        handleClickGoogle={handleClickGoogle}
      />
      <PopoverDownloadApp
        isOpen={isOpenPopover}
        handleDone={() => setIsOpenPopover(false)}
        target="profile-btn"
        title="Download Uthrive App"
        placement="bottom-end"
      />
      <ModalConsentCapture firstname={firstName} isOpen={isOpenConsentCapture} handleContinue={handleConsentCapture} />
      <ModalExistAccount
        isOpen={isOpenModalExistAcc}
        socialName={localStorage.getItem('social_name') || ''}
        onClose={() => {
          setIsOpenModalExistAcc(false);
          localStorage.removeItem('social_name');
        }}
        handleClickCTA={() => {
          setIsOpenModalExistAcc(false);
          setIsSignIn(true);
          setIsOpenModalSignUpMethods(true);
          localStorage.removeItem('social_name');
        }}
      />
      <ModalNotFoundAccount
        isOpen={isOpenModalNotFoundAcc}
        socialName={localStorage.getItem('social_name') || ''}
        onClose={() => {
          setIsOpenModalNotFoundAcc(false);
          localStorage.removeItem('notFoundAcc');
        }}
        handleClickSignin={() => {
          setIsOpenModalNotFoundAcc(false);
          setIsSignIn(true);
          setIsOpenModalSignUpMethods(true);
          localStorage.removeItem('notFoundAcc');
        }}
        handleClickSignup={() => {
          setIsOpenModalNotFoundAcc(false);
          setIsSignIn(false);
          setIsOpenModalSignUpMethods(true);
          localStorage.removeItem('notFoundAcc');
        }}
      />
    </div>
  );
}
