import { SpentCategoryRecordType } from 'types';
import './style.scss';
import { memo, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { formatMoney } from 'utils';
import iconArrow from 'assets/images/two-arrow.png';
import { FadeOutComponent } from 'components/FadeOutComponent';

type props = {
  data: SpentCategoryRecordType[];
  selectedTransaction: SpentCategoryRecordType | null;
  handleClickColumn?: () => void;
  setSelectedTransaction: (val: SpentCategoryRecordType | null) => void;
};

export const SpentByCategoryChart = memo(({ data, selectedTransaction, setSelectedTransaction }: props) => {
  const [showHint, setShowHint] = useState(localStorage?.getItem('show-hint') === 'false' ? false : true);

  useEffect(() => {
    if (showHint) {
      setTimeout(() => {
        setShowHint(false);
        localStorage.setItem('show-hint', 'false');
      }, 2000);
    }
  }, [showHint]);

  const handleClickCol = (val: SpentCategoryRecordType) => {
    if (selectedTransaction?.month === val.month && selectedTransaction?.year === val.year) {
      setSelectedTransaction(null);
      return;
    }
    setSelectedTransaction(val);
  };

  const handleCloseHint = () => {
    setShowHint(false);
    localStorage?.setItem('show-hint', 'false');
  };

  const max = useMemo(() => {
    let temp = data[0]?.totalSpending || 0;
    data?.forEach((e) => {
      if (e.totalSpending > temp) {
        temp = e.totalSpending;
      }
    });
    return temp;
  }, [data]);

  const renderData = useMemo(() => {
    const temp = [...data];
    return temp.reverse();
  }, [data]);

  return (
    <>
      <div className="spent-by-category-chart">
        <div className="spent-by-category-chart-cols">
          {renderData?.map((c, idx) => {
            const checkSelected =
              selectedTransaction && selectedTransaction?.month === c.month && selectedTransaction?.year === c.year;
            return (
              <div
                key={idx}
                className={`spent-by-category-chart-col-container ${!selectedTransaction && 'no-selected'} ${checkSelected ? 'active' : ''}`}
                onClick={() => handleClickCol(c)}>
                <div className="spent-by-category-chart-col" style={{ height: `${(c.totalSpending * 100) / max}%` }}>
                  {checkSelected && (
                    <div className="spent-by-category-chart-amount">${formatMoney(c.totalSpending)}</div>
                  )}
                </div>
                <div className="spent-by-category-chart-label">
                  {moment(`${c.month}/${c.year}`, 'M/YYYY').format("MMM' YY")}
                </div>
              </div>
            );
          })}
        </div>
        <div className="line-container">
          <div className="line-wrapper">
            <div className="line" />
            <div>${formatMoney(Math.round(max / 10) * 10)}</div>
          </div>
          <div className="line-wrapper">
            <div className="line" />
            <div>${formatMoney(Math.round(max / 2 / 10) * 10)}</div>
          </div>
          <div className="line-wrapper">
            <div className="line" />
            <div>$0</div>
          </div>
        </div>
      </div>
      <FadeOutComponent isVisible={showHint}>
        <div className={`chart-hint`}>
          <div className="chart-hint--close" onClick={handleCloseHint}>
            X
          </div>
          <img src={iconArrow} alt="arrow" />
          <div className="chart-hint--text">Move graph or tab a bar to zoom in</div>
        </div>
      </FadeOutComponent>
    </>
  );
});
