import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import chevLeft from 'assets/images/chevron-left.svg';
import { useAppStore, useCardsStore } from 'store';
import { getCardAll, getUserSurvey, submitUserSurvey } from 'services';
import { UserSurveyChoiceOption, UserSurveyQuestion, UserSurveyCategoryOption, UserSurveyAnwserType } from 'types';
import { Input, Label } from 'reactstrap';
import { AppLoadingThreedots, CustomButton, PopupNotify } from 'components';
import { get } from 'lodash';

export const UserSurvey = () => {
  const navigate = useNavigate();

  const { setAppLoading } = useAppStore();
  const { setFeaturedCards } = useCardsStore();

  const [questionsData, setQuestionsData] = useState<UserSurveyQuestion[]>([]);
  const [questionIdx, setQuestionIdx] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showNoti, setShowNoti] = useState(false);

  useEffect(() => {
    fetchUserSurvey();
  }, []);

  const fetchUserSurvey = async () => {
    try {
      setAppLoading(true);
      const resp = await getUserSurvey();

      setShowNoti(!!get(resp?.data, 'questions.0.lastSubmittedAt'));

      const questionsResponsed = (resp?.data?.questions as UserSurveyQuestion[]) || [];
      const temp = [...questionsResponsed];

      temp?.forEach((q) => {
        if (q.isMultiple && q.isMultipleCategory) {
          q?.categoryOptions?.forEach((co) => {
            const check = co?.choiceOptions?.find((op) => op.userSurveyResponse === 'Y');
            if (check) {
              co.isClicked = true;
            } else {
              co.isClicked = false;
            }
          });
        }
      });

      setQuestionsData(temp);
      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const handleContinue = async () => {
    try {
      if (questionIdx !== questionsData?.length - 1) {
        setQuestionIdx(questionIdx + 1);
        setTimeout(() => {
          document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        }, 10);
        return;
      }
      setLoading(true);
      const answers: UserSurveyAnwserType[] = [];
      questionsData?.forEach((q) => {
        q.categoryOptions.forEach((cp) => {
          cp?.choiceOptions?.forEach((choice) => {
            answers.push({ choiceId: choice.choiceId, userSurveyResponse: choice.userSurveyResponse });
          });
        });
      });
      await submitUserSurvey({ answers });
      const resp = await getCardAll();
      setFeaturedCards(resp?.data || []);
      navigate('/cards', { replace: true });

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleChangeRadio = (choice: UserSurveyChoiceOption) => {
    const temp = [...questionsData];
    temp[questionIdx]?.categoryOptions?.forEach((op) => {
      op.choiceOptions.forEach((cp) => {
        if (cp.choiceId === choice.choiceId) {
          cp.userSurveyResponse = 'Y';
        } else cp.userSurveyResponse = 'N';
      });
    });
    setQuestionsData(temp);
  };

  const handleChangeChexbox = (choice: UserSurveyChoiceOption) => {
    const temp = [...questionsData];
    temp[questionIdx]?.categoryOptions?.forEach((op) => {
      op.choiceOptions.forEach((cp) => {
        if (cp.choiceId === choice.choiceId) {
          if (cp.userSurveyResponse === 'N') {
            cp.userSurveyResponse = 'Y';
          } else {
            cp.userSurveyResponse = 'N';
          }
        }
      });
    });
    setQuestionsData(temp);
  };

  const handleClickFakeCheckbox = (choice: UserSurveyCategoryOption) => {
    const temp = [...questionsData];
    temp[questionIdx]?.categoryOptions?.forEach((op) => {
      if (op.categoryName === choice.categoryName) {
        if (op.isClicked) {
          op.choiceOptions?.forEach((cp) => {
            cp.userSurveyResponse = 'N';
          });
        }
        op.isClicked = !op.isClicked;
      }
    });
    setQuestionsData(temp);
  };

  const handleBackBtn = () => {
    if (questionIdx !== 0) {
      setQuestionIdx(questionIdx - 1);
      return;
    }
    navigate(-1);
  };

  const isDisable = useMemo(() => {
    let check = true;
    questionsData[questionIdx]?.categoryOptions.forEach((qd) => {
      qd.choiceOptions?.forEach((coption) => {
        if (coption.userSurveyResponse === 'Y') {
          check = false;
          return false;
        }
      });
    });

    return check;
  }, [questionIdx, questionsData]);

  const title = questionsData[questionIdx]?.title;
  const isMulti = questionsData[questionIdx]?.isMultiple;
  const isMultiCategory = questionsData[questionIdx]?.isMultipleCategory;
  const categoryOptions = questionsData[questionIdx]?.categoryOptions;

  return (
    <div className="user-survey">
      <div className="user-survey-container">
        <div className="user-survey-header">
          <button className="user-survey-btnBack" onClick={handleBackBtn}>
            <img src={chevLeft} alt="chevron-left" />
            Back
          </button>
          <h1>New card preferences</h1>
        </div>
        {showNoti && (
          <PopupNotify
            supportClose
            onClickNo={() => setShowNoti(false)}
            showBtns={false}
            title="Your existing preferences are displayed below. Make updates to refresh recommended cards."
            className="custom-noti"
          />
        )}
        <div className="user-survey-body">
          <div className="user-survey--tag">{`${questionIdx + 1} of ${questionsData?.length} questions`}</div>
          <div className="user-survey-title mb-3">{`${questionIdx + 1}. ${title}`}</div>
          {isMulti && <div className="user-survey-subtitle mb-3">You can choose more than one.</div>}
          {categoryOptions?.map((c) => {
            if (!isMultiCategory && !isMulti) {
              return c.choiceOptions?.map((ch) => {
                return (
                  <div key={ch.choiceId} className="user-survey-category-container">
                    <Input
                      className="user-survey-main-radio"
                      type="radio"
                      name={c.categoryName}
                      onChange={() => handleChangeRadio(ch)}
                      checked={ch.userSurveyResponse === 'Y'}
                    />
                    <Label onClick={() => handleChangeRadio(ch)}>{ch.choiceTitle}</Label>
                  </div>
                );
              });
            }
            if (c.choiceOptions.length === 1) {
              return (
                <div key={c.choiceOptions[0].choiceId} className="user-survey-category-container">
                  <Input
                    className="user-survey-main-checkbox"
                    type="checkbox"
                    checked={c.choiceOptions[0].userSurveyResponse === 'Y'}
                    onChange={() => handleChangeChexbox(c.choiceOptions[0])}
                  />
                  <Label onClick={() => handleChangeChexbox(c.choiceOptions[0])}>{c.categoryName}</Label>
                </div>
              );
            }
            const isHaveIncluded = c.choiceOptions?.find((item) => item.userSurveyResponse === 'Y');
            const isSelected = c.isClicked || false;
            const isChecked = isSelected || isHaveIncluded;
            return (
              <div key={c.categoryName} className="user-survey-category-multi-container">
                <div className="user-survey-category-container">
                  <span
                    className={`user-survey-fake-checkbox ${isChecked ? 'active' : ''}`}
                    onClick={() => handleClickFakeCheckbox(c)}
                  />
                  <Label onClick={() => handleClickFakeCheckbox(c)}>{c.categoryName}</Label>
                </div>
                <div className={`user-survey-category-multi--list ${isChecked ? '' : 'd-none'}`}>
                  {c.choiceOptions?.map((co) => {
                    return (
                      <div key={co.choiceId} className="user-survey-category--item">
                        <img src={co.choiceImage} alt={co.choiceTitle} />
                        <Input
                          className="user-survey-float-checkbox"
                          type="checkbox"
                          checked={co.userSurveyResponse === 'Y'}
                          onChange={() => handleChangeChexbox(co)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <CustomButton onClick={handleContinue} disabled={isDisable}>
          {questionIdx !== questionsData.length - 1 ? 'Continue' : 'Finish'}
        </CustomButton>
      </div>
      {loading && <AppLoadingThreedots loadingText="Figuring out your best cards..." />}
    </div>
  );
};
