import React, { useEffect, useState, useCallback } from 'react';
import { usePlaidLink, PlaidLinkOnSuccess, PlaidLinkOnExit, PlaidLinkOptions } from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const PlaidOAuth = () => {
  const navigate = useNavigate();

  const [token, setToken] = useState<string | null>(null);
  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

  console.log('plaid oauth render');

  React.useEffect(() => {
    if (isOAuthRedirect) {
      setToken(localStorage.getItem('link_token'));
      return;
    }
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    console.log('success');
    toast.success('Plaid linked successfully');
  }, []);

  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    if (error) {
      alert(error || 'Validate Plaid OAuth Failed');
    }
    console.log('plaid oauth render exist');
    navigate('/');
  }, []);

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onExit
  };

  if (isOAuthRedirect) {
    config.receivedRedirectUri = window.location.href;
  }

  const { open, ready } = usePlaidLink(config);
  useEffect(() => {
    if (isOAuthRedirect && ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);
  return null;
};
