import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './style.scss';
import chevLeft from 'assets/images/chevron-left.svg';
import searchIcon from 'assets/images/search-icon.png';
import closeIcon from 'assets/images/nav-toggle-close.png';
import { getMappingCards, linkedMappingUpdateCard } from 'services';
import { useAppStore } from 'store';
import { ExistingAccountType, MappingCardDataType } from 'types';
import { CustomButton, MappingCard, ModalExistingCardMapping } from 'components';
import { Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { unknowErr } from 'const';

export const CardValidateSelection = () => {
  const navigate = useNavigate();
  const { accountId } = useParams();

  const { setAppLoading } = useAppStore();

  const [searchTerm, setSearchTerm] = useState('');
  const [mappingCardData, setMappingCardData] = useState<MappingCardDataType | null>(null);
  const [selectedCard, setSelectedCard] = useState(-111);
  const [cardName, setCardName] = useState('');
  const [existingAccount, setExistingAccount] = useState<ExistingAccountType | null>(null);

  useEffect(() => {
    fetchCardMappingFailed();
  }, [accountId]);

  const fetchCardMappingFailed = async () => {
    try {
      if (!accountId) return;
      setAppLoading(true);
      const resp = await getMappingCards(accountId);
      setMappingCardData(resp.data);
      if (resp?.data?.otherCardUserPrompt) {
        setCardName(resp?.data?.otherCardUserPrompt);
        setSelectedCard(0);
      }
      setAppLoading(false);
    } catch (err: any) {
      console.log(err);
      setAppLoading(false);
      navigate(-1);
    }
  };

  const handleConfirm = async () => {
    try {
      if (selectedCard === 0 && cardName?.trim()?.length < 4) {
        toast.warn('Please enter at least 4 characters');
        return;
      }
      setAppLoading(true);
      const params: any = {
        accountId: accountId,
        isConfirm: 'N'
      };
      if (cardName && selectedCard === 0) {
        params.otherCard = cardName?.trim();
      } else {
        params.mappedCardId = selectedCard;
      }
      const resp = await linkedMappingUpdateCard(params);
      setAppLoading(false);
      if (resp?.data?.isExistAccount) {
        setExistingAccount(resp?.data);
        return;
      }
      if (resp.error) {
        toast.error(resp.error);
        return;
      }
      // toast.success('Validated successfully');

      navigate('/linked-account/card-validation', { replace: true });
    } catch (err: any) {
      setAppLoading(false);
      toast.error(err?.response?.data?.message || unknowErr);
      console.log(err);
    }
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setSearchTerm(val);
  };

  const handleCheck = (id: number) => {
    setSelectedCard(id);
    if (id !== 0 && !!cardName) {
      setCardName('');
    }
  };

  const handleChangeCardName = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setCardName(val);
  };

  const handleCloseModal = () => {
    setExistingAccount(null);
    setSelectedCard(-111);
    setCardName('');
  };

  const handleConfirmExistingAccount = async () => {
    try {
      if (selectedCard === 0 && cardName?.trim()?.length < 4) {
        toast.warn('Please enter at least 4 characters');
        return;
      }
      setAppLoading(true);
      const params: any = {
        accountId: accountId,
        isConfirm: 'Y'
      };
      if (cardName && selectedCard === 0) {
        params.otherCard = cardName;
      } else {
        params.mappedCardId = selectedCard;
      }
      const resp = await linkedMappingUpdateCard(params);
      setAppLoading(false);
      if (resp?.data?.isExistAccount) {
        setExistingAccount(resp?.data);
        return;
      }
      if (resp.error) {
        toast.error(resp.error);
        return;
      }
      // toast.success('Validated successfully');

      navigate('/linked-account/card-validation', { replace: true });
    } catch (err: any) {
      setAppLoading(false);
      toast.error(err?.response?.data?.message || unknowErr);
      console.log(err);
    }
  };

  const handleBackBtn = () => {
    navigate(-1);
  };

  const mappingCards = useMemo(() => {
    return (
      mappingCardData?.cards?.filter((c) =>
        c.mappedCard?.toLocaleLowerCase()?.includes(searchTerm?.toLocaleLowerCase())
      ) || []
    );
  }, [searchTerm, mappingCardData]);

  const isDisabledBtn =
    selectedCard === -111 || (selectedCard === 0 && (!cardName?.trim() || cardName?.trim()?.length < 4));

  return (
    <>
      <div className="card-validate-selection">
        <button className="linked-account-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <div className="linked-account-header mb-4">
          <h2 className="linked-account-header-title text-center">Card Selection</h2>
        </div>
        <div className="card-validate-selection-title">{`${mappingCardData?.institutionName} ${mappingCardData?.accountSubtype} ${mappingCardData?.accountMask ? '- ' + mappingCardData?.accountMask : ''}`}</div>
        <div className="card-validate-selection-subtitle">
          Please validate the name of above card from the options below to link it successfully:
        </div>
        <div className="card-validate-selection-search-container">
          <input value={searchTerm} onChange={handleChangeSearch} placeholder="Search card name" />
          {searchTerm ? (
            <img src={closeIcon} alt="close" onClick={() => setSearchTerm('')} className="cursor-pointer" />
          ) : (
            <img src={searchIcon} alt="search" />
          )}
        </div>
        <div className="card-validate-selection-cards-container mt-4">
          {mappingCards?.map((c, idx) => {
            return (
              <MappingCard
                key={idx}
                data={c}
                isCheck={c.mappedCardId === selectedCard}
                handleCheck={() => handleCheck(c.mappedCardId)}
              />
            );
          })}
          <div className="card-validate-selection-input-card" onClick={() => handleCheck(0)}>
            <Input type="radio" checked={selectedCard === 0} readOnly />
            <Input
              value={cardName}
              onChange={handleChangeCardName}
              placeholder="Enter account name, e.g., Chase Total Checking"
            />
          </div>
        </div>
        <div className="card-validate-selection-footer">
          <CustomButton onClick={handleConfirm} disabled={isDisabledBtn}>
            Confirm
          </CustomButton>
        </div>
      </div>
      <ModalExistingCardMapping
        data={existingAccount}
        isOpen={!!existingAccount}
        onClose={handleCloseModal}
        handleConfirm={handleConfirmExistingAccount}
      />
    </>
  );
};
