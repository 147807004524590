import { useNavigate, useParams } from 'react-router-dom';
import './style.scss';
import chevLeft from 'assets/images/chevron-left.svg';
import iconCard from 'assets/images/icon-Cards.png';
import { useEffect, useState } from 'react';
import { useAppStore } from 'store';
import { getSpentByMerchant, getSpentTransactionsByMerchant } from 'services';
import { SpentByMerchantType, SpentTransactionRecordType } from 'types';
import moment from 'moment';
import { fixMoney } from 'utils';
import { toast } from 'react-toastify';
import { get } from 'lodash';

const limit = 10;

export const SpentByMerchantDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const merchantParam = params?.merchant || '';
  const typeParam = params?.type || '';

  const { setAppLoading } = useAppStore();

  const [spentByMerchantData, setSpentByMerchantData] = useState<SpentByMerchantType | null>(null);
  const [transactions, setTransactions] = useState<SpentTransactionRecordType[]>([]);
  const [hasMoreTransaction, setHasMoreTransaction] = useState(false);

  useEffect(() => {
    if (!merchantParam || !typeParam) {
      navigate(-1);
    }
  }, [params, merchantParam, typeParam]);

  useEffect(() => {
    fetchSpentByMerchant();
  }, [merchantParam]);

  const fetchSpentByMerchant = async () => {
    try {
      setAppLoading(true);
      const resps = await Promise.all([
        getSpentByMerchant(merchantParam),
        getSpentTransactionsByMerchant({ limit, offset: 0 }, merchantParam)
      ]);
      setSpentByMerchantData(resps[0]?.data);
      setTransactions(resps[1].data || []);
      if (resps[1].data?.length < limit) {
        setHasMoreTransaction(false);
      } else {
        setHasMoreTransaction(true);
      }
      setAppLoading(false);
    } catch (err: any) {
      setAppLoading(false);
      toast.error('Something went wrong');
      navigate('/spent-by-category');
      console.log(err);
    }
  };

  const fetchMoreTransactionByMerchant = async () => {
    try {
      setAppLoading(true);
      const resp = await getSpentTransactionsByMerchant({ limit, offset: transactions.length }, merchantParam);
      setTransactions([...transactions, ...(resp.data || [])]);
      if (resp.data?.length < limit) {
        setHasMoreTransaction(false);
      } else {
        setHasMoreTransaction(true);
      }
      setAppLoading(false);
    } catch (err: any) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleBackBtn = () => {
    navigate(-1);
  };

  return (
    <div className="spent-by-merchant">
      <button className="spent-by-merchant-btnBack" onClick={handleBackBtn}>
        <img src={chevLeft} alt="chevron-left" />
        Back
      </button>
      <div className="spent-by-merchant-header">
        <h2 className="spent-by-merchant-header-title text-center">{`${merchantParam} ${typeParam}`}</h2>
      </div>
      <div>
        <div className="spent-by-merchant--info">
          <img src={spentByMerchantData?.merchantImageUrl || spentByMerchantData?.categoryImageUrl} alt="merchant" />
          <div className="spent-by-merchant--name">
            <div>{spentByMerchantData?.mappedMerchant}</div>
            <div>Last Paid on: {moment(spentByMerchantData?.lastPaidDate).format('MM/DD/YYYY')}</div>
          </div>
        </div>
        <div className="spent-by-merchant--title">How you pay & Earn rewards</div>
        <div className="spent-by-merchant--card-info">
          <div className="spent-by-merchant--card-name">
            <img src={spentByMerchantData?.yourCard?.cardImageUrl} alt="card" />
            <div>{`${spentByMerchantData?.yourCard?.institutionShortname} ${spentByMerchantData?.yourCard?.accountSubtype} ${spentByMerchantData?.yourCard?.accountMask ? '- ' + spentByMerchantData?.yourCard?.accountMask : ''}`}</div>
          </div>
          <div className="spent-by-merchant--card-reward">
            <div>
              {get(spentByMerchantData?.yourCard, 'rewardMultiplier')
                ? `${get(spentByMerchantData?.yourCard, 'rewardMultiplier')}${get(spentByMerchantData?.yourCard, 'rewardType', '') === 'Cash back' ? `%` : 'X'}`
                : '0X'}
            </div>
            <div>
              {get(spentByMerchantData?.yourCard, 'rewardType', '')?.length
                ? get(spentByMerchantData?.yourCard, 'rewardType', '')
                : 'Rewards'}
            </div>
          </div>
        </div>
      </div>
      {spentByMerchantData?.potentialCard ? (
        <>
          {spentByMerchantData?.potentialCard?.isYourCard ? (
            <>
              <div className="spent-by-merchant--earned">
                <div className="spent-by-merchant--tag">Best Card for {merchantParam}</div>
                <div className="spent-by-merchant--earned-text">
                  You can earn <span>${fixMoney(spentByMerchantData?.potentialCard?.totalMissedReward || 0)}</span> more
                  on {merchantParam} and other {get(spentByMerchantData, 'category')} purchases with your other card:
                </div>
                {spentByMerchantData?.potentialCard && (
                  <div className="d-flex align-items-center spent-by-merchant--potential-card">
                    <img
                      src={spentByMerchantData?.potentialCard?.cardImageUrl || spentByMerchantData?.categoryImageUrl}
                      alt="card"
                    />
                    <div>{`${spentByMerchantData?.potentialCard?.institutionShortname} ${spentByMerchantData?.potentialCard?.accountSubtype} ${spentByMerchantData?.potentialCard?.accountMask ? '- ' + spentByMerchantData?.potentialCard?.accountMask : ''}`}</div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="spent-by-merchant--earned">
                <div className="spent-by-merchant--tag">
                  Recommended Card for {get(spentByMerchantData, 'category')}
                </div>
                <div className="spent-by-merchant--earned-text">
                  You can earn <span>${fixMoney(spentByMerchantData?.potentialCard?.totalMissedReward || 0)}</span> more
                  on {get(spentByMerchantData, 'category')} and overall earn{' '}
                  <span>{fixMoney(get(spentByMerchantData?.potentialCard, 'totalMissedRewardAll'))}</span> on other
                  purchases with this new card:
                </div>
                {spentByMerchantData?.potentialCard && (
                  <div className="d-flex align-items-center spent-by-merchant--potential-card">
                    <img src={iconCard} alt="card" />
                    <div>View our recommended new card</div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <div className="spent-by-merchant--earned">
          <div className="spent-by-merchant--tag">Best Card for {merchantParam}</div>
          <div className="spent-by-merchant--earned-text">
            You are earning maximum rewards with this card. You&apos;ve earned{' '}
            <span>${fixMoney(spentByMerchantData?.yourCard.totalEarnedReward || 0)}</span> on {merchantParam} and other
            purchases.
          </div>
          {spentByMerchantData?.potentialCard && (
            <div className="d-flex align-items-center spent-by-merchant--potential-card">
              <img src={spentByMerchantData?.potentialCard?.cardImageUrl} alt="card" />
              <div>{`${spentByMerchantData?.potentialCard?.institutionShortname} ${spentByMerchantData?.potentialCard?.accountSubtype} ${spentByMerchantData?.potentialCard?.accountMask ? '- ' + spentByMerchantData?.potentialCard?.accountMask : ''}`}</div>
            </div>
          )}
        </div>
      )}
      <div className="spent-by-merchant--title">Recent payment</div>
      <div>
        {transactions?.map((t) => {
          return (
            <div key={t.transactionGenId} className="spent-by-merchant--transaction">
              <div className="spent-by-merchant--transaction-info">
                <img src={t.cardImageUrl} alt="card" />
                <div className="spent-by-merchant--transaction-info-name">
                  <div>{`${t.institutionShortName} ${t.accountSubtype} ${t.accountMask ? '- ' + t.accountMask : ''}`}</div>
                  <div>Paid on: {moment(t.transactionDate).format('MM/DD/YYYY')}</div>
                </div>
              </div>
              <div className="spent-by-merchant--transaction-amount">${fixMoney(t.transactionAmount)}</div>
            </div>
          );
        })}
        {hasMoreTransaction && (
          <div className="btn-load-more" onClick={fetchMoreTransactionByMerchant}>
            Load more
          </div>
        )}
      </div>
    </div>
  );
};
