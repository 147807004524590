import { LinkedCardRecordType } from 'types';
import iconWarning from 'assets/images/warning.png';
import iconExclamation from 'assets/images/exclamation.png';
import iconCheck from 'assets/images/check.png';
import './style.scss';

type props = {
  data: LinkedCardRecordType;
  handleClick?: () => void;
  isUpdate?: boolean;
  className?: string;
  isRefresh?: boolean;
  handleRefresh?: () => void;
};

export const LinkedCard = ({
  data,
  handleClick,
  isUpdate = true,
  className = '',
  isRefresh = false,
  handleRefresh
}: props) => {
  return (
    <div className={`linked-card ${className}`}>
      {data?.linkedStatus === 'P' && (
        <div className="linked-card--exclamation">
          <img src={iconExclamation} alt="Exclamation" />
        </div>
      )}
      {!isUpdate && data?.linkedStatus === 'Y' && (
        <div className="linked-card--exclamation">
          <img src={iconCheck} alt="Check" />
        </div>
      )}
      <div className="linked-card--info">
        <img src={data.cardImage} alt="card" />
        <div>{`${data?.cardShortName} ${data?.accountSubtype} ${data?.accountMask ? '- ' + data?.accountMask : ''}`}</div>
      </div>
      <div className="linked-card--action">
        {isUpdate ? (
          <>
            <img src={iconWarning} alt="warning" />
            <div onClick={handleClick ? handleClick : undefined}>
              {data?.linkedStatus === 'P' ? 'Pending Premium' : 'Renew Connection'}
            </div>
          </>
        ) : (
          <>
            {isRefresh && <div onClick={handleRefresh ? handleRefresh : undefined}>Refresh</div>}
            <div onClick={handleClick ? handleClick : undefined}>
              {data?.linkedStatus === 'Y' ? 'Unlink' : data.linkedStatus === 'P' ? 'Pending Premium' : 'Relink'}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
